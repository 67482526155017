const checkRole = (user, requiredRoles) => {
    if (!user || !user.role) return false;
  
    // Convert the requiredRoles to an array if it's a string
    let rolesArray;
    if (typeof requiredRoles === 'string') {
      rolesArray = requiredRoles.split(',').map(role => role.trim());
    } else if (Array.isArray(requiredRoles)) {
      rolesArray = requiredRoles;
    } else {
      return false; // If requiredRoles is neither an array nor a string, return false
    }
  
    return rolesArray.includes(user.role);
  };
  
  export default checkRole;
  