import React, { useEffect, useState, useContext, useRef, useReducer } from 'react';
import { Container, Typography, Button, Paper, Snackbar, Alert, Box, Grid, Divider, CircularProgress, IconButton, Dialog, DialogContent, DialogTitle, getListItemSecondaryActionClassesUtilityClass, Tooltip } from '@mui/material';
import Layout from '../components/Layout';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import LogsIcon from '@mui/icons-material/Topic';
import PreviewIcon from '@mui/icons-material/Preview';
import PinIcon from '@mui/icons-material/Pin';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ContactsIcon from '@mui/icons-material/Contacts';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { AuthContext } from '../AuthContext';
import HelpIcon from '@mui/icons-material/Help';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import '../styles.css'; 
import { useNavigate, useLocation } from 'react-router-dom';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

export default function HomePage() {
    const [data, setData] = React.useState(null);
    const [credentials, setCredentials] = React.useState('', '');
    const [showPassword, setShowPassword] = useState(false);
    const [keepalive, setKeepalive] = useState(null); 
    const { globalTenantId } = useContext(AuthContext);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const globalTenantIdRef = useRef(globalTenantId);
    const [subscriptionInfoLoading, setSubscriptionInfoLoading] = useState(true);
    const [kioskStatusLoading, setKioskStatusLoading] = useState(true);
    const [kioskCredentialsLoading, setKioskCredentialsLoading] = useState(true); 
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const [showSubscriptionPanel, setShowSubscriptionPanel] = useState(false);
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth <= mobileBreakpoint;
    //const [firstTimeUser, setFirstTimeUser] = useState(true);

    useEffect(() => {
        globalTenantIdRef.current = globalTenantId;
    }, [globalTenantId]);

    useEffect(() => {
        // Function to check if all targeted elements are available
        const areElementsAvailable = () => {
          // Assuming you have multiple steps with data-intro
          const elements = document.querySelectorAll('[data-intro]');
          return elements.length > 8;
        };
      
        const startIntro = () => {
          const intro = introJs();
          intro.setOptions({dontShowAgain: true, showProgress: true}).start();
            intro.oncomplete(function() {
                introJs().setDontShowAgain(true);
          });
          
          
        };
      
        if (areElementsAvailable()) {
          startIntro();
        } else {
          // Optional: Retry after a delay if elements are not available immediately
          const timeoutId = setTimeout(startIntro, 2000); // Adjust delay as needed
          return () => clearTimeout(timeoutId); // Cleanup timeout
        }
      }, []);
      
      
    // Callback to open a checkout
    const openCheckout = () => {
        navigate('/client-admin/upgrade'); 
    };

    const handlePreviewKiosk = () => {
        if (!globalTenantId) {
          console.log('No tenantid in context');
          return;
        }
  
        // show a popup with the kiosk in it
        let kioskUrl;
        axios.get('/api/setting?name=KIOSKURL')
        .then(result => {
            const data = result.data;
            kioskUrl = data.value + '?tenantId=' + globalTenantId;
            window.open(kioskUrl, 'kiosk', 'width=400,height=600');
          })
        .catch(error => console.error('Error fetching data:', error))
  
      };

    // Retrieve success message from the state
    useEffect(() => {
        if (location.state?.message) {
            setSuccessMessage(location.state.message);
            setShowSuccess(true);
        }
    }, [location]);

    const fetchPageData = () => {
        if (globalTenantId == null) return;
        
        setKioskStatusLoading(true);
        setKioskCredentialsLoading(true);
        axios.get(`/api/tenants/${globalTenantId}`, { params: { fields: ['OrganisationName'] } }).then(result => {
            setData(result.data);
        }).catch(error => {
            console.error('Error fetching data:', error)
        });
        axios.post('/api/kioskStatus', {}, { params: { tenantId: globalTenantId } }).then(result => {
            setKeepalive(result.data);
            setKioskStatusLoading(false);
          }).catch(error => {
            console.error('Error fetching kiosk status:', error);
          });
          
        axios.get('/api/kioskCredentials', { params: { tenantId: globalTenantId } }).then(result => {
            setCredentials(result.data);
            setKioskCredentialsLoading(false);
        }).catch(error => {
            console.error('Error fetching kiosk credentials:', error);
        });

        if (user.role == 'CLIENT_ADMIN') {
            setShowSubscriptionPanel(true);
            fetchSubscriptionData();
        }
    
    };

    const fetchSubscriptionData = () => {
        setSubscriptionInfoLoading(true);
        axios.get('/api/customer-subscription', { params: { tenantId: globalTenantIdRef.current } })
        .then(result => {
            const newPlan = JSON.parse(JSON.stringify(result.data));  // this must be done to force a re-render
            setSubscriptionPlan(newPlan);

            // Determine if the subscription is annual
            setSubscriptionInfoLoading(false);

        }).catch(error => {
            console.error('Error fetching customer sub:', error);
        });
    }

    useEffect(() => {
        fetchPageData();
    }, [globalTenantId]); // An empty dependency array means this effect will run once after the initial render
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

      const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => { console.log('Copied to clipboard'); },
            (err) => { console.error('Could not copy text: ', err); }
        );
    };

    const handleKioskHelp = () => {
        window.open('https://www.entrinsic.io/installation-guide', '_blank');
    };

    const StyleSheet = {
        HomeIcon: {
            marginRight: 10,
            marginBottom: 20,
            float: 'left',
            verticalAlign: 'middle' 
        }
    };

    return (
        
        <Layout title={ isMobile ? ' ' : 'Entrinsic Connect Dashboard' }>
            <Grid container spacing={5}>
                <Grid item xs={12} lg={showSubscriptionPanel ? 8 : 12 }>
                    <Typography variant='body1' style={{marginBottom: 20}}>You are now viewing device <b>{data ? data.OrganisationName : '...'}.</b></Typography>
                    
                    {
                        isMobile ?
                        (
                            <>
                                <Typography variant='body1'>
                                    The web portal is not optimised for mobile devices. Please use a desktop, laptop or tablet computer to access the full functionality.
                                    If you are looking for the mobile app(s) you can download it from the following links:
                                    <Grid container spacing={2} style={{marginTop:10}} >
                                        <Grid item xs={12}>
                                        <Paper elevation={10} style={{marginLeft:0}} >
                                        <Box p={2}>
                                            <Typography variant="body1" component="div" sx={{ backgroundColor: '#888', color: '#fff', padding: '3px', margin: '-10px', marginBottom: '10px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>APPS</Typography>
                                            <Typography>Entrinsic Connect (Responder)</Typography>
                                            <a href='https://apps.apple.com/us/app/entrinsic-connect/id6473429546?platform=iphone'>
                                                <img src='/Appstore.png' alt='App Store' width={100} />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Connect&pcampaignid=web_share'>
                                                <img src='/googleplay.png' alt='Google Play' width={100} />
                                            </a>
                                            <Typography>Entrinsic Connect Kiosk</Typography>
                                            <a href='https://apps.apple.com/ph/app/entrinsic-connect-kiosk/id6473428401'>
                                                <img src='/Appstore.png' alt='App Store' width={100} />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Kiosk'>
                                                <img src='/googleplay.png' alt='Google Play' width={100} />
                                            </a>
                                        </Box>
                                    </Paper>
                                        </Grid>
                                    </Grid>
                                </Typography>   
                            </>
                        ) : (
                            <Typography variant='body2'>
                        You have
                        a number of options to manage and configure your device. Use the menu in the top right corner to switch device.
                    </Typography>
                        )
                    }
                    <Grid container alignItems="center" spacing={2} style={{marginTop:5}}>
                        { !isMobile && (
                        <>
                            
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='logs'><LogsIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Viewing logs, including kiosk activity and status</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='number-plates'><LayersIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Listing vehicle registrations plates, allowing entry as needed</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='pins'><PinIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Setting PINs for people to gain entry with</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='video-messages'><VideoChatIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Reviewing video messages that people have left for you</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='snapshots'><PhotoLibraryIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Viewing snapshots and distinguishing between movement and non-movement captures</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='movement-videos'><OndemandVideoIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Viewing movement videos</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <PreviewIcon color='primary' style={{ cursor: 'pointer' }} fontSize='large' style={StyleSheet.HomeIcon} onClick={handlePreviewKiosk} />
                            <Typography variant='body2'>Viewing the how the Kiosk looks without logging into it</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='settings'><SettingsIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Changing any settings that affect how your Kiosk displays and functions</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <a href='users'><PeopleIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                            <Typography variant='body2'>Configure which users can access this console, as well as to respond to visitors (applies to all devices)</Typography>
                        </Grid>
                        { showSubscriptionPanel && (
                            <>
                                <Grid item xs={12} lg={6} md={6}>
                                    <a href='responder-aliases'><ContactsIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                                    <Typography variant='body2'>Configure aliases to be used if the Choose Responder setting is enabled on any kiosk, or for ANPR / PIN groupings</Typography>
                                </Grid>
                                {   
                                    !user.partnerId || user.is_partner_user && (
                                        <Grid item xs={12} lg={6} md={6}>
                                            <a href='upgrade'><UpgradeIcon color='primary' fontSize='large' style={StyleSheet.HomeIcon} /></a>
                                            <Typography variant='body2'>Upgrade or downgrade your subscription plan</Typography>
                                        </Grid>
                                        )
                                }
                            </>
                        )}
                        </>
                        
                        )}
                        <Grid container spacing={2} style={{marginTop:10}} >
                            <Grid item xs={12} md={7} lg={7}>
                                <Paper elevation={10} style={{marginLeft:15}} data-intro='Use these details to login to your device or kiosk. Click the help icon to find out more.'>
                                    <Box p={2}>
                                        <Typography variant="body1" component="div" sx={{ backgroundColor: '#888', color: '#fff', padding: '3px', margin: '-10px', marginBottom: '10px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={11}>
                                                LIVE KIOSK LOGIN DETAILS
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title='Click here to find out how to set up your device'>
                                                    <HelpIcon onClick={handleKioskHelp} />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        </Typography>
                                        {
                                        kioskCredentialsLoading ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                        <>
                                    
                                        <Grid container spacing={0} alignItems="center">
                                            <Grid item xs={4} lg={4}>
                                                <Typography variant="body1">Username:</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Typography variant="body1">{credentials.email || 'Loading...'}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton aria-label="copy" size="small" onClick={() => copyToClipboard(credentials.email || '')}>
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={4} lg={4}>
                                                <Typography variant="body1">Password:</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Typography variant="body1">{showPassword ? credentials.password : '******'}
                                                <Button variant="outlined" size="small" onClick={togglePasswordVisibility} style={{marginLeft: '10px'}}>{showPassword ? 'Hide' : 'Show'}</Button>
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item xs={2}>
                                                { showPassword && (
                                                <IconButton aria-label="copy" size="small" onClick={() => copyToClipboard(showPassword ? credentials.password : '')}>
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                                ) }
                                            </Grid>
                                        </Grid>
                                        </>)
                                        }
                                    </Box>

                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5}>
                                <Paper elevation={10} style={{marginLeft:15}} data-intro='You can see the status of your device here' >
                                    <Box p={2}>
                                    <Typography variant="body1" component="div" sx={{ backgroundColor: '#888', color: '#fff', padding: '3px', margin: '-10px', marginBottom: '10px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                                        DEVICE STATUS
                                    </Typography>
                                    {
                                    kioskStatusLoading ? (
                                        <CircularProgress size={25} />
                                    ) : (
                                        <>
                                    
                                        { !keepalive && <Typography variant="body1" style={{color: 'red', fontWeight: 'bold'}}>DEVICE OFFLINE</Typography>}
                                        { 
                                            keepalive && ((keepalive > 2) ? 
                                                <Typography variant="body1" style={{color: 'red', fontWeight: 'bold'}}>DEVICE OFFLINE</Typography> : 
                                                <Typography variant="body1" style={{color: 'green', fontWeight: 'bold'}}>ONLINE</Typography>
                                            )
                                        }
                                        { keepalive && (<Typography>Last seen {keepalive.toFixed(1)} minute(s) ago</Typography> ) }
                                        </>
                                    )
                                    }
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                { showSubscriptionPanel && (
                <Grid item xs={12} lg={4}>
                    <Paper elevation={10} data-intro='Here are the details of your account subscription (not specific to any one device). Click Upgrade to gain access to more advanced features of Entrinsic Connect.'>
                    <Box p={2}>
                        <Typography variant="body1" component="div" sx={{ backgroundColor: '#888', color: '#fff', padding: '3px', margin: '-10px', marginBottom: '10px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                            SUBSCRIPTION PLAN
                        </Typography>
                        { subscriptionInfoLoading ? (
                            <Grid container direction="column" spacing={2}>
                                <Grid container item alignItems="center">
                                    <Grid item xs={10}>

                                        <Typography variant="body2" noWrap style={{marginBottom: 20}}>Loading subscription...</Typography>
                                        <CircularProgress size={25} />
                                    </Grid>
                                </Grid>
                            </Grid>) :
                        (
                            <>
                            <Grid container direction="column" spacing={2}>
                                <Grid container item alignItems="center">
                                    <Grid item xs={12}>
                                    <Typography variant="body1" component="div" sx={{ backgroundColor: '#eee', padding: '3px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                                        {subscriptionPlan.plan.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Kiosks:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.NumberOfAllowedKiosks}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Responders:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.NumberOfAllowedResponders}</Typography>
                                    </Grid>
                                </Grid>                                
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Movement Storage Days:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.MovementDetectionDaysStorage}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Movement Videos:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.MovementVideoAllowed ? 'Yes' : 'No'}</Typography>
                                    </Grid>
                                </Grid>
                                
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>ANPR:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan?.ANPRAllowedPlates > 0 ? `Yes - ${subscriptionPlan.plan?.ANPRAllowedPlates} plates` : 'No'}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Kiosk Customisation:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.AdvancedKioskCustomisation ? 'Yes' : 'No'}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Gate Opening:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.GateOpening ? 'Yes' : 'No'}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrap><strong>Log Days Storage:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.LogDaysStorage}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" noWrazp><strong>Aliases (multi tenant):</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{subscriptionPlan.plan.NumberOfAllowedAliases}</Typography>
                                    </Grid>
                                </Grid>
                                { subscriptionPlan.paddleData.items &&
                                (
                                    <>
                                <Grid container item alignItems="center">
                                    <Grid item xs={12}>
                                    <Typography variant="body1" component="div" sx={{ backgroundColor: '#eee', padding: '3px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                                        Billing Info</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{subscriptionPlan.paddleData.items[0].price.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="body2" noWrap><strong>Price:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{subscriptionPlan.paddleData.recurring_transaction_details.totals.currency_code} {subscriptionPlan.paddleData.recurring_transaction_details.totals.total / 100}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="body2" noWrap><strong>Next billed:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {(() => {
                                            if (subscriptionPlan && subscriptionPlan.paddleData && subscriptionPlan.paddleData.items && subscriptionPlan.paddleData.items.length > 0) {
                                                const nextBilledDate = new Date(subscriptionPlan.paddleData.items[0].next_billed_at);
                                                return nextBilledDate.toLocaleDateString();
                                            }
                                            return '';
                                        })()}
                                    </Typography>
                                    </Grid>
                                </Grid>
                                </>
                                )}

                            </Grid>

                            {   
                                !user.partnerId && (
                                <Button variant="contained" color="primary" onClick={openCheckout} style={{marginTop:20}}>{subscriptionPlan.paddleData.items ? 'CHANGE OR UPGRADE' : 'UPDGRADE'}</Button>
                                )
                                
                            }
                            {
                                user.is_partner_user && (
                                    <Typography variant='body2' style={{marginTop: 20, color: '#1215c4'}}>To change your customer's subscription level please contact Entrinsic Connect Support</Typography>
                                )
                            }
                            {
                                user.partnerId && !user.is_partner_user && (
                                    <Typography variant='body2' style={{marginTop: 20, color: '#1215c4'}}>To change your subscription level please contact {user.partnerName}</Typography>
                                )
                            }
                         
                         {showSuccess && (
                            <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            open={showSuccess}
                            autoHideDuration={5000}
                            message={successMessage}
                            onClose={() => setShowSuccess(false)}
                            >
                                <Alert severity="success" onClose={() => setShowSuccess(false)}>
                                {successMessage}
                                </Alert>
                            </Snackbar>
                        )}
                         </>
                        )}

                        </Box>
                    </Paper>
                </Grid>
                )}

            </Grid>
        </Layout>
        
    );
    
        

    
}
