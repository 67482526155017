import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function HelpPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isSuccess = queryParams.get('success') === 'true';
    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
                <img src='/Entrinsic_Logo.png' style={{marginBottom: 20}}/>
            <Typography component="h1" variant="h5" style={{marginBottom: 20}}>
                Sorry to confuse things, but...
            </Typography>
            <Typography variant="body1">
                This account doesn't have admin access to any devices or kiosks. You are probably logging in to this page
                having followed a link in an email that was sent to you. Instead of logging in here, you need to download the
                Entrinsic Connect App and login there instead. Here's the links you need:
            </Typography>
            <Box p={2} style={{textAlign: 'center'}}>
                <a href='https://apps.apple.com/us/app/entrinsic-connect/id6473429546?platform=iphone'>
                    <img src='/Appstore.png' alt='App Store' width={300} />
                </a>
                <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Connect&pcampaignid=web_share'>
                    <img src='/googleplay.png' alt='Google Play' width={300} />
                </a>
            </Box>
                
            <p><a href='/login'>Back to Login</a></p>
        </Box>
        </Container>
        </ThemeProvider>

    );
}

export default HelpPage;
