import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function VerifiedPage() {
    const defaultTheme = createTheme();

    const [isVerified, setIsVerified] = useState(null);

    useEffect(() => {
        // Extract the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const token = urlParams.get('token');


        console.log(email, token)
        // Make a POST request to your backend
        fetch("/api/verify", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email, token: token})
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                setIsVerified(true);
            } else {
              console.log(data);
                setIsVerified(false);
            }
        });
    }, []);
    if (isVerified === null) {
      return <p>Verifying...</p>;
    }
    return (
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verfied
          </Typography>
            {isVerified ? (
                <>
                    <p>Email verified!</p>
                    <Link href="/Login" variant="body2">Go to login</Link>
                </>
            ) : (
                <p>Failed to verify email. Please try again.</p>
            )}
        </Box>
        </Container>
        </ThemeProvider>

    );
}

export default VerifiedPage;
