import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import checkRole from './checkRole';

const ProtectedRoute = ({ requiredRole, children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user, loading } = useContext(AuthContext); // Add loading here
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // Do nothing, just wait
      return;
    }
    
    if (!user) {
      setIsAuthorized(false);
      navigate('/login');
    } else if (requiredRole && !checkRole(user, requiredRole)) {
      setIsAuthorized(false);
      navigate('/unauthorized');
    } else {
      setIsAuthorized(true);
    }
  }, [user, navigate, requiredRole, loading]); // Add loading here

  if (loading) {
    return <div>Loading...</div>; // Handle loading state
  }

  if (!isAuthorized) {
    return null; // Don't render children if the user isn't authorized.
  }

  return children; // If the conditions are met, render the children
};

export default ProtectedRoute;
