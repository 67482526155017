import React from 'react';
import Navigation from '../Navigation';

function Layout({ children, title }) {
    return (
        <Navigation title={title}>
            {children}
        </Navigation>
    );
}

export default Layout;
