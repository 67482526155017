import React from 'react';

const ThemeGallery = ({ themes, selectedTheme, onThemeSelect, onCustomThemeSelect }) => {
  const handleThemeClick = (themeId, themeName) => {
    onThemeSelect(themeId);

    if (themeName === 'Custom') {
      onCustomThemeSelect();
    }
  };

  const isSelected = (themeId) => selectedTheme === themeId;

   // Style for the lozenge
   const lozengeStyle = {
    display: 'inline-block',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '3px 8px',
    fontSize: '15px',
    borderRadius: '10px',
    marginLeft: '5px'
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {themes.map((theme) => (
        <div key={theme.id} style={{ margin: '10px', cursor: 'pointer', textAlign: 'center' }}>
          {theme.shortName === 'custom' ? (
            <div
              onClick={() => handleThemeClick(theme.id, theme.name)}
              style={{
                width: '100px',
                height: '150px',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: isSelected(theme.id) ? '3px solid green' : '1px solid grey',
                boxSizing: 'border-box',
                marginBottom: '10px'
              }}>
              {/* Text or icon for custom */}
            </div>
          ) : (
            <img
              src={`/ThemePreviews/${theme.shortName}.png`}
              alt={theme.name}
              onClick={() => handleThemeClick(theme.id, theme.name)}
              style={{
                border: isSelected(theme.id) ? '3px solid green' : '1px solid grey',
                width: '100px',
                height: '150px',
                objectFit: 'cover',
                marginBottom: '5px'
              }}
            />
          )}
          <div>
          {isSelected(theme.id) ? <span style={lozengeStyle}>{theme.name}</span> : theme.name}
         </div>
        </div>
      ))}
    </div>
  );
};

export default ThemeGallery;
