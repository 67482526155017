import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize the primary color
    },
    secondary: {
      main: '#f50057', // Customize the secondary color
    },
    // ... add other customization as needed
  },
  typography: {
    // Customize typography settings
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    // Here you can add other typography customizations if needed
  },
  // ... add other theme customizations
});

theme = responsiveFontSizes(theme);

export default theme;
