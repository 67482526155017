import { Toolbar, Typography, IconButton, InputLabel, Grid, Tooltip } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import React, {useState} from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { GlobalListItems, MainListItems, SecondaryListItems } from './listItems';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import { useContext } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, ListItem, ListItemText, TextField, FormControl, CircularProgress } from '@mui/material';
import { useUnreadCount } from '../../UnreadCountContext';
import { DeviceManagement, setNumberOfAvailableKiosks } from '../Settings/DeviceManagement';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import theme from '../../theme/theme';


function Navigation( { children, title }) {
    const drawerWidth = 240;
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth <= mobileBreakpoint;

 
    const [open, setOpen] = React.useState(!isMobile);
    const [userRole, setUserRole] = React.useState('');
    const { user } = useContext(AuthContext);
    const { setUser } = useContext(AuthContext);
    const [kiosks, setKiosks] = useState([]);
    const { globalTenantId, setGlobalTenantId } = useContext(AuthContext);  
    const [selectedKiosk, setSelectedKiosk] = useState(globalTenantId);
    const [numberOfAvailableKiosks, setNumberOfAvailableKiosks] = useState(1); // Example number, populate from the API if needed
    const [kioskTypes, setKioskTypes] = useState([]);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const { unreadCounts, setUnreadCounts, refreshUnreadCounts } = useUnreadCount();

    React.useEffect(() => {
      if (user && user.role) {
        setUserRole(user.role);
      }
    }, [user]);

    React.useEffect(() => {
      refreshKiosks();
    }, [selectedKiosk]);  // Dependency array now includes selectedKiosk
  
    const fetchUnreadCounts = async () => {
      let counts = {};
      for (const kiosk of kiosks) {
        try {
          const response = await axios.get('/api/videoMessage-unread', { params: { tenantId: kiosk.id } });
          counts[kiosk.id] = response.data.count;
        } catch (error) {
          console.error(`Error fetching unread count for kiosk ${kiosk.id}:`, error);
          counts[kiosk.id] = 0; // or handle the error differently
        }
      }
      setUnreadCounts(counts);
    };

    // Assign the fetching logic to the context's method
    React.useEffect(() => {
      refreshUnreadCounts.current = fetchUnreadCounts;
    }, [fetchUnreadCounts]);
    
    React.useEffect(() => {
      if (kiosks.length === 0) return;
      fetchUnreadCounts();
    }, [kiosks, globalTenantId]);
    
    
    const refreshKiosks = () => {
      axios.get('/api/kiosks?adminOnly=true')
        .then(response => {
          if (response.data.length === 0) {
            // no access to kiosks - log out
            handleSignOut(true);
            return;
          }
          setKiosks(response.data);
          //set numberofavailablekiosks
          setNumberOfAvailableKiosks(response.data[0].Customer.NumberOfAllowedKiosks);
          
          // Only set to 'current' kiosk if none is already selected
          if (globalTenantId === null) {
            const currentKiosk = response.data.find(kiosk => kiosk.current === true);
            if (currentKiosk) {
              setSelectedKiosk(currentKiosk.id);
              // Optionally update the global tenantId here if needed
              setGlobalTenantId(currentKiosk.id); 
            }
            else
            {
              setSelectedKiosk(globalTenantId);
            }
          }


        })
        .catch(error => {
          console.error('Error fetching kiosks:', error);
          // Additional error handling logic here
        });
         // populate kiosk types dropdown
      axios.get('/api/kiosk-types')
      .then(response => {
        if (response.data.length === 0) {
          // no access to kiosks - log out
          throw new Error('No kiosk types available');
        }
        // populate dropdown
        setKioskTypes(response.data);
                
      })
      .catch(error => {
        console.error('Error fetching kiosk types:', error);
        // Additional error handling logic here
      });
    }

     const handleKioskChange = (event) => {
      const selectedKioskId = event.target.value;
      setSelectedKiosk(selectedKioskId);
      setGlobalTenantId(selectedKioskId);  
    };    

    const toggleDrawer = () => {
      const newState = !open;
      setOpen(newState);
      localStorage.setItem("drawerOpen", JSON.stringify(newState));
  };
  
  React.useEffect(() => {
    const storedState = localStorage.getItem('drawerOpen');

    if (storedState !== null && !isMobile) {
      setOpen(storedState === "true");
    }
  }, []); 
  

    const navigate = useNavigate();

    const handleGoToLogs = () => {
      navigate('/client-admin/logs');
      if (isMobile) {
        setOpen(false);
      }
    };    
    const handleOnGoToNumberPlates = () => {
      navigate('/client-admin/number-plates');
      if (isMobile) {
        setOpen(false);
      }

    };    
    const handleGoToPins = () => {
      navigate('/client-admin/pins');
      if (isMobile) {
        setOpen(false);
      }

    };     
    
    const handleGoToSettings = () => {
      navigate('/client-admin/settings');
      if (isMobile) {
        setOpen(false);
      }
    }; 
    const handleGoToMessages = () => {
      navigate('/client-admin/video-messages');
      if (isMobile) {
        setOpen(false);
      }
    }; 
    const handleGoToSnapshots = () => {
      navigate('/client-admin/snapshots');
      if (isMobile) {
        setOpen(false);
      }

    };     
    const handleGoToMovementVideos = () => {
      navigate('/client-admin/movement-videos');
      if (isMobile) {
        setOpen(false);
      }

    }; 
    const handleGoToUsers = () => {
      navigate('/client-admin/users');
      if (isMobile) {
        setOpen(false);
      }

    }; 
    const handleGoToResponderAliases = () => {
      navigate('/client-admin/responder-aliases');
      if (isMobile) {
        setOpen(false);
      }

    }; 

    const handleGoToHome = () => {
      navigate('/client-admin/');
      if (isMobile) {
        setOpen(false);
      }

    };
    const handleUpgrade = () => {
      navigate('/client-admin/upgrade');
      if (isMobile) {
        setOpen(false);
      }

    };
    const handleSignOut = async (redirectToHelpPage = false) => {
        try {
            // Make a GET request to the logout route in the backend
            const response = await fetch('/api/logout');

            if (response.ok) {
                // clear the session state
                //sessionStorage.removeItem('user');
                setUser(null);

                // If logout was successful, redirect to the login page
                if (redirectToHelpPage === true) {
                  navigate('/help');
                } else {
                  navigate('/login');
                }
            } else {
                // Handle any errors that might occur during logout
                console.error('Failed to log out');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handlePreviewKiosk = () => {
      if (!globalTenantId) {
        console.log('No tenantid in context');
        return;
      }

      // show a popup with the kiosk in it
      let kioskUrl;
      axios.get('/api/setting?name=KIOSKURL')
      .then(result => {
          const data = result.data;
          kioskUrl = data.value + '?tenantId=' + globalTenantId;
          window.open(kioskUrl, 'kiosk', 'width=400,height=600');
        })
      .catch(error => console.error('Error fetching data:', error))

    };
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
      })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }));
      const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
          '& .MuiDrawer-paper': {
            position: isMobile ? 'fixed' : 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
              [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
              },
            }),
          },
        }),
      );
      function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://entrinsic.io/">
              Entrinsic Connect
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

      const openSettingsDialog = () => {
        setSettingsDialogOpen(true); 
      }
      const handleSettingsClose = () => {
        setSettingsDialogOpen(false);
      }
     
      const anyUnreads = Object.values(unreadCounts).some(count => count > 0);

      

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }} data-intro='Welcome to Entrinsic Connect. Please follow this short guided tour of the web portal. Feel free to close the window to come back to this next time you visit the Dashboard. Alternatively select "Dont show this again" to hide this tour.'>
          <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title || "Dashboard"}
            </Typography>
            { user.partnerId && !user.is_partner_user && (
              <Tooltip title="To add new devices please contact your partner">
            <Typography variant='body2' style={{marginRight: 20, color: '#cccccc'}}>Your system is managed by {user.partnerName}</Typography>
            </Tooltip>
            )}
             {userRole.startsWith('CLIENT_') && (
              <>
              { !isMobile && (
              <Typography variant='body2' style={{marginRight: 10}}>Switch device <ArrowRightIcon fontSize='small' color='action' style={{verticalAlign: 'middle'}} /></Typography>
              )}
          <Badge
              color="primary"
              badgeContent=''
              invisible={!anyUnreads}
              sx={{
                '.MuiBadge-badge': {
                  height: '12px',
                  minWidth: '12px',
                  fontSize: '0.4rem',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  top: '10px',
                }
              }}
            >
              <Select
                value={selectedKiosk}
                onChange={handleKioskChange}
                label="Kiosk"
                style={{ color: '#f0f0f0' }} 
                data-intro='This is the device selector. The dashboard, and most of the menus on the left, are specific to the device you have selected here, assuming you have more than one device.'

              >
                {kiosks.map((kiosk) => {
                return  (
                    
                        <MenuItem value={kiosk.id}>
                          {kiosk.OrganisationName}
                          {unreadCounts[kiosk.id] > 0 && (
                            <span style={{
                                marginLeft: '10px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                color: 'white',
                                display: 'inline-block',
                                padding: '0 6px',
                                fontSize: '0.75rem',
                                lineHeight: '1.5',
                                verticalAlign: 'middle'
                            }}>
                                {unreadCounts[kiosk.id]}
                            </span>
                          )}
                        </MenuItem>
                    
                    )})}
              </Select>
            </Badge>
            </>
            )}   
            {userRole.startsWith('CLIENT_ADMIN') && (!user.partnerId || user.is_partner_user) && (     
            <IconButton color="inherit" data-intro='Use this button to show the Manage Devices screen. This is where you can add more devices.'>
              <SettingsIcon onClick={openSettingsDialog} />
          </IconButton>)}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
            >
            {/* add an image with the entrinsic logo below... */}
            <img src="/Entrinsic_Logo.png" alt="Company Logo" width="150px" style={{marginRight: 10}} />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>

          </Toolbar>
          <Divider />
          <List component="nav">
            <div data-intro='Most of the menu options here are specific to the device selected in the top right.'>
              <MainListItems 
                            userRole={userRole}
                            onGoToLogs={handleGoToLogs}
                            onGoToMovementVideos={handleGoToMovementVideos}
                            onGoToDashboard={handleGoToHome}
                            onGoToNumberPlates={handleOnGoToNumberPlates}
                            onGoToPins={handleGoToPins} 
                            onGoToMessages={handleGoToMessages}
                            onGoToSnapshots={handleGoToSnapshots}
                            unreadCount={unreadCounts[selectedKiosk] || 0}
                            />
              <Divider sx={{ my: 1 }} />
              <SecondaryListItems userRole={userRole}
                                  user={user}
                                  onPreviewKiosk={handlePreviewKiosk}
                                  onGoToSettings={handleGoToSettings}
                                  unreadCount={unreadCounts[selectedKiosk] || 0}
                            />
              </div>
            <Divider sx={{ my: 1 }} />
            <div data-intro='These menu options apply to all devices.'>
            <GlobalListItems userRole={userRole}
                                user={user}
                                onSignOut={handleSignOut}
                                onGoToUsers={handleGoToUsers}
                                onGoToResponderAliases={handleGoToResponderAliases}
                                onUpgrade={handleUpgrade}
                                unreadCount={unreadCounts[selectedKiosk] || 0}
                          />
            </div>
          </List>
         
        </Drawer>
       
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingLeft: '50px',
            paddingRight: isMobile ? 0 : '50px',
          }}
        ><Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            {children}
          <Copyright sx={{ pt: 4 }} />
          </Container>
          </Box>
        </Box>
        
        {userRole.startsWith('CLIENT_ADMIN') && (   
        <DeviceManagement kiosks={kiosks}
                          kioskTypes={kioskTypes}
                          numberOfAvailableKiosks={numberOfAvailableKiosks}
                          settingsDialogOpen={settingsDialogOpen}
                          setSettingsDialogOpen={setSettingsDialogOpen}
                          setGlobalTenantId={setGlobalTenantId}
                          setSelectedKiosk={setSelectedKiosk}
                          refreshKiosks={refreshKiosks}
                          handleSettingsClose={handleSettingsClose}
                          />
        )}

      
    </ThemeProvider>
    );
}

export default Navigation;
