import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, FormControlLabel, Container,
        Grid, Box, Dialog, DialogTitle,
        DialogContent, DialogActions, Snackbar, Alert,
        Tab, Tabs } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const CustomerGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'id',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false); // New state for loading status
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [openingId, setOpeningId] = useState(null);
    const [openedId, setOpenedId] = useState(null);
    const [filters, setFilters] = useState({
        search: '',
        excludeKeepalive: false
    });
    const [customerDetailsDialogOpen, setCustomerDetailsDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isAddingNewTenant, setIsAddingNewTenant] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [newTenantName, setNewTenantName] = useState('');

    // State for form inputs
    const [form, setForm] = useState({
    });
    

    const filterRef = useRef(null);
    // effect hooks
    useEffect(() => {
        fetchData();
        // useeffect initially fires twice because of strict mode in index.js. Should not remove that - see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
        
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort]);
    
    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchData();
    };

    const fetchData = () => {
        setLoading(true);

        const params = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort,
            search: filters.search,
        };

        axios.get('/api/customers', { params })
        .then(result => {
           const data = result.data;
            setRows(data.rows || []);
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false); // Set loading to false when fetch is done (either success or error)
        });
    };

    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/customers/${id}`)
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            setErrorMessage(error.response.data.includes('FOREIGN KEY') ? 'Unable to delete customer due to associated tenants or users' : error.response.data);
            setShowAlert(true);
        });
    }

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleCustomerDetailsDialogClose = () => {
        setCustomerDetailsDialogOpen(false);
    };
    const handleOpenCustomer = (id) => {
       fetchCustomerDetails(id)
    };

    function fetchCustomerDetails(id)
    {
        // get the customer from API
        setOpeningId(id);

        axios.get(`/api/customerDetails`, { params: { id } })
        .then(result => {
            setIsEditing(true);
            setForm(result.data);
            setCustomerDetailsDialogOpen(true);
            setOpeningId(null);
            setOpenedId(id);
        })
        .catch(error => {
            console.error('Error deleting customer:', error)
        });
    }

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        setLoadingUpdate(true);
    
        axios.put(`/api/customers/${openedId}`, form)
        .then(data => {
            resetForm();
            fetchData();
            setLoadingUpdate(false);
        })
        .catch(error => {
            setErrorMessage(error.response.data);
            setShowAlert(true);
            setLoadingUpdate(false);
        });
        
    };
    function resetForm()
    {
        setForm({ });
        // clear the selected kiosks
        setIsEditing(false);
        setCustomerDetailsDialogOpen(false);
        setOpenedId(false);
    }
    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleAddNewTenant = (e) => {
        e.preventDefault();
        setIsAddingNewTenant(true);
        axios.post('/api/tenants', { tenant: {OrganisationName: newTenantName, CustomerId: openedId} }, { params: { customerId: openedId } })
        .then(result => {
            setIsAddingNewTenant(false);
            // Refresh the list of tenants
            fetchCustomerDetails(openedId);
        })
        .catch(error => {
            setIsAddingNewTenant(false);
            setErrorMessage(error.message);
            setShowAlert(true);
        });
    };

    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "name", headerName: "Name", width: 250 },
        
        { field: "createdAt", headerName: "Created", width: 250,
            valueGetter: (params) => {
                const date = new Date(params.value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            }
        },
        { field: 'open', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleOpenCustomer(params.id)}
                variant="contained"
                color="primary"
            >
                {openingId == params.id ? <CircularProgress size={24} /> : 'Open'}
            </Button>
        ),
        },
        { field: 'delete', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },

    ];
      
    return (
       
            <Layout title='Customers'>
                <form onSubmit={handleFilterSubmit} ref={filterRef}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={10} >
                            <TextField
                                fullWidth
                                label="Search"
                                name="search"
                                variant="outlined"
                                margin="normal"
                                value={filters.search}
                                onChange={handleFilterChange}                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <Button type="submit" variant="contained" color="primary" fullWidth >
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                <Box my={4}>
                    
                    {/* Show DataGrid if not loading */}
                    {
                        <div style={{ height: gridHeight, width: '100%' }}>
                            <DataGrid
                                loading={loading} 
                                rows={rows} 
                                columns={columns} 
                                pageSizeOptions={[10]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                onPaginationModelChange={setPaginationModel}
                                onSortModelChange={setSortModel}
                                rowCount={rowCount}
                                sortingOrder={['desc', 'asc']}
                                
                            />
                        </div>
                    }
                </Box>
                <Dialog
                    open={customerDetailsDialogOpen}
                    onClose={handleCustomerDetailsDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth={false}
                    fullWidth={true}
                    sx={{ m: 2 }}
                    >
                    <DialogTitle id="form-dialog-title">{isEditing ? 'Edit User' : 'Add User'}</DialogTitle>
                    <form onSubmit={handleUpdateSubmit}>
                        <DialogContent>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        {...form.name ? { value: form.name } : {}}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>

                            <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
                                <Tab label="Tenants" />
                                <Tab label="Users" />
                            </Tabs>
                            
                            {tabValue === 0 && (
                                <>
                                <Box style={{ height: 400, width: '100%' }}>
                                    { form.tenants && (<DataGrid
                                        rows={form.tenants}
                                        columns={Object.keys(form.tenants?.[0] || {}).map((key) => ({
                                            field: key,
                                            headerName: key,
                                            width: 150,
                                        }))}
                                    /> ) }
                                </Box>
                                <Button onClick={()=> setIsAddingNewTenant(true)}>Add new tenant</Button>
                                { isAddingNewTenant && (
                                    <Dialog
                                    open={customerDetailsDialogOpen}
                                    onClose={() => setIsAddingNewTenant(false)}
                                    aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">Add new tenant</DialogTitle>
                                        <form onSubmit={handleAddNewTenant}>
                                            <DialogContent>
                                            <TextField
                                                fullWidth
                                                label="Name of new tenant"
                                                name="newTenantName"
                                                variant="outlined"
                                                margin="normal"
                                                onChange={(e) => setNewTenantName(e.target.value)}
                                            />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setIsAddingNewTenant(false)} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button type="submit" color="primary">Add</Button>
                                            </DialogActions>   
                                        </form>
                                    </Dialog>
                                )}
                                </>
                            )}
                            {tabValue === 1 && (
                                <Box style={{ height: 400, width: '100%' }}>
                                    { form.users && (<DataGrid
                                        rows={form.users}
                                        columns={[
                                            { field: 'id', headerName: 'ID', width: 100 },
                                            { field: 'email', headerName: 'Email', width: 200 },
                                            { field: 'first_name', headerName: 'First Name', width: 150 },
                                            { field: 'last_name', headerName: 'Last Name', width: 150 },
                                            { field: 'role', headerName: 'Role', width: 150 },
                                            { field: 'verified', headerName: 'Verified', width: 130 },
                                            { field: 'createdAt', headerName: 'Created At', width: 200 },
                                        ]}
                                    /> ) }
                                </Box>
                            )}

                            <DialogActions>
                                <Button onClick={handleCustomerDetailsDialogClose} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    { loadingUpdate ? <CircularProgress size={24} /> : 'Update' }
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </form>
                </Dialog>
                {showAlert && (
                    <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={showAlert}
                    autoHideDuration={5000}
                    message={errorMessage}
                    onClose={() => setShowAlert(false)}
                    >
                        <Alert severity="error" onClose={() => setShowAlert(false)}>
                        {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                            
                {showSuccess && (
                    <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={showSuccess}
                    autoHideDuration={5000}
                    message={successMessage}
                    onClose={() => setShowSuccess(false)}
                    >
                        <Alert severity="success" onClose={() => setShowSuccess(false)}>
                        {successMessage}
                        </Alert>
                    </Snackbar>
                )}
            </Layout>
        );
}

export default CustomerGrid;
