import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.entrinsic.io/">
          Entrinsic Connect
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
const defaultTheme = createTheme();

function HandleResetPassword() {
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    

    const location = useLocation();
    const token = location.search.substring(1);
    
    
    const isStrongPassword = (password) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;

        return regex.test(password);
    };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');

    if (!isStrongPassword(password)) {
      setPasswordError("Password should be strong with at least 8 characters, including uppercase, lowercase, number, and a special character.");
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match!");
      return;
    } else {
      setConfirmPasswordError("");
    }

    try {
      const response = await fetch('/api/handleresetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: password,
          token: token
        })
      });

      const responseData = await response.json();
      if (responseData.success) {
        // Handle success, e.g., navigate to login or show a success message
        navigate('/login?passwordResetSuccess=true');  
        

      } else {
        // Handle error
        setAlertMessage(responseData.message);
        setShowAlert(true);
      }
    } catch (error) {
        setAlertMessage("An error occurred. Please try again.");
        setShowAlert(true);
    
    }
};

    return (
        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      error={Boolean(passwordError)}
                      helperText={passwordError}
                  />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      autoComplete="new-password"
                      error={Boolean(confirmPasswordError)}
                      helperText={confirmPasswordError}
                  />
              </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              
              
            </Box>
          </Box>
          
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    );
}

export default HandleResetPassword;
