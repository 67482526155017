import { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { routes } from './routesConfig';
export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false); // Add this state
  const [globalTenantId, setGlobalTenantId] = useState(null);  // <-- Add these lines

  const fetchUserRole = async () => {
    try {
      const response = await axios.get("/api/userRole");
      if (response.data) {
        setUser({ role: response.data.role, id: response.data.id, 
          email: response.data.email, 
          partnerId: response.data.partnerId, 
          partnerName: response.data.partnerName,
          is_partner_user: response.data.is_partner_user,
        });
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      if (window.location.pathname !== '/login') { // Add this line
        setHasError(true);
        window.location.href = '/login?redirect=' + window.location.pathname;
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const isPublic = routes.some(route => route.path === window.location.pathname && route.isPublic);
    if (!isPublic) {
      fetchUserRole();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size={24} />
    </div>
  );
  
  return (
    <AuthContext.Provider value={{ user, setUser, globalTenantId, setGlobalTenantId }}>
      {children}
    </AuthContext.Provider>
  );
}
