import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { TextField, Tooltip, Button, Checkbox, FormControlLabel, Select, InputLabel, MenuItem, Grid, Box, Alert, Snackbar, Dialog, DialogActions, DialogTitle, DialogContent, Typography, Icon, IconButton, FormControl } from '@mui/material';
import axios from 'axios';
import HelpIcon from '@mui/icons-material/Help';
import { AuthContext } from '../../AuthContext';

const UsersGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [initialSelectedKiosks, setInitialSelectedKiosks] = useState(new Set());
    const { user } = useContext(AuthContext);

    const [kiosks, setKiosks] = useState([]);
    const [selectedKiosks, setSelectedKiosks] = useState(new Set());
    const [selectedKioskAdmins, setSelectedKioskAdmins] = useState(new Set());
    const [selectedVideoAccess, setSelectedVideoAccess] = useState(new Set());
    const [selectedMovementNotifications, setSelectedMovementNotifications] = useState(new Set());

    const [kioskSelectionError, setKioskSelectionError] = useState('');


    useEffect(() => {
        //console.log('Refreshing user list and tenants');
        fetchData();

    }, []);
    
    const roleOptions = [
        { value: 'CLIENT_ADMIN', label: 'Admin' },
        { value: 'CLIENT_USER', label: 'User' }
      ];

      
    const fetchTenants = async () => {
        //console.log('fetching tenants')
        await axios.get('/api/kiosks')
        .then(result => {
            setKiosks(result.data);
        })
        .catch(error => {
            console.error('Error fetching kiosks:', error);
        });
    };

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 150;
        
        setGridHeight(calculatedHeight);
    }, []); 
    
    // State for form inputs
    const [form, setForm] = useState({
        email: '', first_name: '', last_name: '', role: 'CLIENT_USER'
    });
    
    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // Function to handle kiosk checkbox changes
    const handleKioskCheckboxChange = (e) => {
        setKioskSelectionError('');
        const kioskId = Number(e.target.name); // Convert to number
        setSelectedKiosks(prevSelectedKiosks => {
            const newSelectedKiosks = new Set(prevSelectedKiosks);
            const isChecked = e.target.checked;
    
            if (isChecked) {
                newSelectedKiosks.add(kioskId);
            } else {
                newSelectedKiosks.delete(kioskId);
                // If Responder is unticked, untick Admin, Allow Adhoc Video, and Movement Notifications
                if (selectedKioskAdmins.has(kioskId)) {
                    setSelectedKioskAdmins(prev => {
                        const updated = new Set(prev);
                        updated.delete(kioskId);
                        return updated;
                    });
                }
                if (selectedVideoAccess.has(kioskId)) {
                    setSelectedVideoAccess(prev => {
                        const updated = new Set(prev);
                        updated.delete(kioskId);
                        return updated;
                    });
                }
                if (selectedMovementNotifications.has(kioskId)) {
                    setSelectedMovementNotifications(prev => {
                        const updated = new Set(prev);
                        updated.delete(kioskId);
                        return updated;
                    });
                }
            }
            return newSelectedKiosks;
        });
    };
    
    
    const handleKioskAdminCheckboxChange = (event) => {
        const kioskId = parseInt(event.target.name.split("-")[1], 10); 
        setSelectedKioskAdmins(prevSelectedKioskAdmins => {
            const newSelectedKioskAdmins = new Set(prevSelectedKioskAdmins);
            if (event.target.checked) {
                newSelectedKioskAdmins.add(kioskId);
            } else {
                newSelectedKioskAdmins.delete(kioskId);
            }
            return newSelectedKioskAdmins;
        });
    };
    
    const handleVideAccessCheckboxChange = (event) => {
        const kioskId = parseInt(event.target.name.split("-")[1], 10); 
        setSelectedVideoAccess(preSelectedVideoAccess => {
            const newSelectedVideoAccess = new Set(preSelectedVideoAccess);
            if (event.target.checked) {
                newSelectedVideoAccess.add(kioskId);
            } else {
                newSelectedVideoAccess.delete(kioskId);
            }
            return newSelectedVideoAccess;
        });
    };

    const handleMovementNotificationsCheckboxChange = (event) => {
        const kioskId = parseInt(event.target.name.split("-")[1], 10); 
        setSelectedMovementNotifications(preSelectedMovementNotifications => {
            const newAccess = new Set(preSelectedMovementNotifications);
            if (event.target.checked) {
                newAccess.add(kioskId);
            } else {
                newAccess.delete(kioskId);
            }
            return newAccess;
        });
    };
    
    

    // Function to add responder (assuming that you have a User ID)
    // Convert addResponder to an async function
    const addResponder = async (userId, tenantId, IsKioskAdmin, allowAdhocVideoAccess, AllowMovementNotifications) => {
        try {
            const response = await axios.post('/api/userResponders', { 
                UserID: userId, 
                TenantID: tenantId, 
                IsKioskAdmin: IsKioskAdmin, 
                AllowAdhocVideoAccess: IsKioskAdmin || allowAdhocVideoAccess,
                AllowMovementNotifications: IsKioskAdmin || AllowMovementNotifications 
            });
            //console.log('Responder added:', response.data);
        } catch (error) {
            console.error('Error adding responder:', error);
            throw error; // Rethrow the error so it can be caught by the caller
        }
    };
    
    // Function to delete responder (assuming that you have a User ID)
    const deleteResponder = async (userId, tenantId) => {
        await axios.delete(`/api/userResponders`, { params: { userId, tenantId } })
        .then(response => {
            //console.log('Responder deleted:', response.data);
        })
        .catch(error => {
            console.error('Error deleting responder:', error);
        });
    };
    
    
    // Function to handle form submission
    const handleAddSubmit = async (e) => {
        e.preventDefault();
    
        if (selectedKiosks.size === 0 && form.role === 'CLIENT_USER') {
            setKioskSelectionError('You have not selected any kiosks. This means the user will not have access to anything. You cannot proceed unless you select at least one kiosk.');
            return;
        }
    
        setKioskSelectionError('');
        setLoadingAddOrUpdate(true);
    
        try {
            const data = await axios.post('/api/users', form);
            const newUserId = data.data.id;
        
            if (form.role === 'CLIENT_USER') {
                const kiosksToAdd = Array.from(selectedKiosks);
        
                for (const tenantId of kiosksToAdd) {
                    //console.log('About to add ' + tenantId);
                    const isAdmin = selectedKioskAdmins.has(tenantId);
                    const isVideoAccess = selectedVideoAccess.has(tenantId);
                    const isMovementAccess = selectedMovementNotifications.has(tenantId);
                    
                    try {
                        await addResponder(newUserId, tenantId, isAdmin, isVideoAccess, isMovementAccess);
                    } catch (error) {
                        console.log(error);
                        setLoadingAddOrUpdate(false);
                        setErrorMessage(error.response.data);
                        setShowAlert(true);
                        
                    }
                }
            }
            else
            {
                await addAdminResponderToAllKiosks(newUserId);
               
                
            }
    
            setSuccessMessage('User added successfully. They will receive an email with a link to set their password.');
            setShowSuccess(true);
            setDialogOpen(false);
            resetForm();
            fetchData();
        } catch (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setShowAlert(true);
        } finally {
            setLoadingAddOrUpdate(false);
        }
    };
    
    const addAdminResponderToAllKiosks = async (userId) => {

        // add kiosk admin to ALL kiosks
        for (const tenant of kiosks) {
            //console.log('About to add admin ' + tenant.id);
            
            try {
                await addResponder(userId, tenant.id, true, false, true);
            } catch (error) {
                console.log(error);
                setLoadingAddOrUpdate(false);
                setErrorMessage(error.response.data);
                setShowAlert(true);
                
            }
        }
    }

    const fetchUserResponders = (userId) => {
        axios.get(`/api/userResponders?id=${userId}`)
        .then(result => {
            const existingKioskIds = new Set(result.data.map(r => r.TenantID));
            setSelectedKiosks(existingKioskIds);
            setInitialSelectedKiosks(new Set(existingKioskIds));
            setSelectedKioskAdmins(new Set(result.data.filter(r => r.IsKioskAdmin).map(r => r.TenantID)));
            setSelectedVideoAccess(new Set(result.data.filter(r => r.AllowAdhocVideoAccess).map(r => r.TenantID)));
            setSelectedMovementNotifications(new Set(result.data.filter(r => r.AllowMovementNotifications).map(r => r.TenantID)));

        })
        .catch(error => {
            console.error('Error fetching user responders:', error);
        });
    };
    
    const fetchData = () => {
        setGridLoading(true);
        axios.get('/api/users')
        .then(result => {
            let data = result.data;
            
            setGridLoading(false);
            setRows(data);
            
        })
        .catch(error => {
            setGridLoading(false);
            console.error('Error fetching data:', error);
            setShowAlert(true);
        });
    };



    // Function to handle editing
    const handleEditClick = async (entry) => {
        await fetchTenants();
        setForm({ email: entry.email, first_name: entry.first_name, last_name: entry.last_name, role: entry.role });
        // get the user responders
        fetchUserResponders(entry.id);
        setIsEditing(true);
        setEditedEntry(entry);
        setKioskSelectionError('');
        handleDialogOpen(); // Open the dialog and set edit mode
    };

    
    // Function to handle update submission
   // Update handleUpdateSubmit
    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true);

        try {

            // check if number of responders will be breached
            const data = await axios.put(`/api/users/${editedEntry.id}`, form);
            let errorOccurred = false;

            if (form.role == 'CLIENT_USER') { //only do this for client_user

                // Delete all initially selected kiosks
                initialSelectedKiosks.forEach(tenantId => {
                    deleteResponder(editedEntry.id, tenantId);
                });

                for (const tenantId of selectedKiosks) {
                    const isAdmin = selectedKioskAdmins.has(tenantId);
                    const isVideoAccess = selectedVideoAccess.has(tenantId);
                    const isMovementAccess = selectedMovementNotifications.has(tenantId);
                    try {
                        await addResponder(editedEntry.id, tenantId, isAdmin, isVideoAccess, isMovementAccess);
                    } catch (error) {
                        console.log(error);
                        setLoadingAddOrUpdate(false);
                        setErrorMessage(error.response.data);
                        setShowAlert(true);
                        errorOccurred = true;
                        break; // Exit the loop if an error occurs
                    }
                }
            }
            else
            {
                // delete all existing responders
                for (const tenant of kiosks) {
                    await deleteResponder(editedEntry.id, tenant.id);
                }

                await addAdminResponderToAllKiosks(editedEntry.id);
            }
            if (!errorOccurred) {
                setIsEditing(false);
                setLoadingAddOrUpdate(false);
                setDialogOpen(false);
                resetForm();
                fetchData();
            }
        } catch (error) {
            setLoadingAddOrUpdate(false);
            setErrorMessage(error.response.data);
            setShowAlert(true);
        }
    };


    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/users/${id}`)
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            // Refresh the list of users
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            setErrorMessage('Error deleting user - it may be in use');
            setShowAlert(true);
            console.error('Error deleting user:', error)
        });
    };

    function resetForm()
    {
        setForm({ email: '', first_name: '', last_name: '', role: 'CLIENT_USER' });
        // clear the selected kiosks
        setSelectedKiosks(new Set());
        setIsEditing(false);
        setEditedEntry(null);
    }

    // Function to handle dialog open/close
    const handleDialogOpen = async () => {
        await fetchTenants();
        setSelectedKioskAdmins(new Set());
        setSelectedVideoAccess(new Set());
        setSelectedMovementNotifications(new Set());
        setKioskSelectionError('');
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        resetForm();
    };
    const columns = [
        // 100% of available space

        { field: "email", headerName: "Email", flex: 1},
        { field: "first_name", headerName: "First name", flex: 1},
        { field: "last_name", headerName: "Last name", flex: 1},
        { field: 'role', headerName: 'Role', width: 150},
        {
            field: "edit",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button 
                    disabled={ params.row.is_partner_user && !user.is_partner_user }
                    onClick={() => handleEditClick(params.row)} 
                    variant="contained" 
                    color="primary"
                >
                    Edit
                </Button>
            )
        },
        { field: 'delete', headerName: '', width: 100,        
            renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id) || params.id === user.id || params.row.is_partner_user}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>

            )
        },
    ];

    return (
        <Layout title='Users'> 
        <Grid container>
            <Grid item xs={10}>
                <Typography>Note - users are global to all kiosks</Typography>  
            </Grid>
            <Grid item xs={2}>
            <Button onClick={() => handleDialogOpen(null)} variant="contained" color="primary">
                Add User
            </Button>
            </Grid>
        </Grid>

            {/* MUI Dialog for adding or editing user */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">{isEditing ? 'Edit User' : 'Add User'}</DialogTitle>
                <DialogContent>
                    {  !isEditing && (<Typography variant="body1" gutterBottom>
                        After adding a new user, they will receive an email with a link to set their password.
                    </Typography>) }
                    
                    <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit}>
                        
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        value={form.email}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="First name"
                                        name="first_name"
                                        value={form.first_name}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Last name"
                                        name="last_name"
                                        value={form.last_name}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth margin='normal'>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={10}>
                                                <InputLabel id="role-label" style={{ zIndex: 10000 }}>Role</InputLabel>
                                                <Select
                                                    fullWidth
                                                    
                                                    labelId='role-label'
                                                    label="Role"
                                                    name="role"
                                                    value={form.role}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    {roleOptions.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title='Admin users have full access to all devices, billing, upgrades and user management. Regular Users only have the specific access to devices that you give them on this screen.'>
                                                    <HelpIcon />
                                                </Tooltip> 
                                            </Grid>
                                        </Grid>                                   
                                    </FormControl>
                                </Grid>

                            </Grid>
                            {
                                form.role == 'CLIENT_USER' && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Tooltip title="The selected devices and kiosks below are 'Responders' meaning they can login to the app, view the snapshot of the device, access the camera and respond to incoming calls. Additionally with admin access, the user can access both the portal and have access within the app to change device settings. Ad-hoc video access means they can connect to video on demand.">
                                        <Typography variant="h6" style={{marginTop:30}}>App access to Device/kiosks
                                            <IconButton>
                                                <HelpIcon/>
                                            </IconButton>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={2}>Responder</Grid>
                                    <Grid item xs={2}>Admin</Grid>
                                    <Grid item xs={2}>Allow Adhoc Video</Grid>
                                    <Grid item xs={2}>Receive Movement Notifications</Grid>
                                </Grid>
                                {kiosks.map((kiosk) => (
                                    <Grid container item xs={12} spacing={3} key={kiosk.id}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">{kiosk.OrganisationName}</Typography>
                                        </Grid>
                                       
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedKiosks.has(kiosk.id)}
                                                        onChange={handleKioskCheckboxChange}
                                                        name={kiosk.id.toString()} // Convert to string to match checkbox's `name` prop type
                                                    />
                                                }
                                                
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedKioskAdmins.has(kiosk.id)}
                                                        onChange={handleKioskAdminCheckboxChange}
                                                        disabled={!selectedKiosks.has(kiosk.id)}
                                                        name={`admin-${kiosk.id}`} // Convert to string to match checkbox's `name` prop type
                                                    />
                                                }
                                                
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedKioskAdmins.has(kiosk.id) || selectedVideoAccess.has(kiosk.id)}
                                                        onChange={handleVideAccessCheckboxChange}
                                                        disabled={selectedKioskAdmins.has(kiosk.id) || !selectedKiosks.has(kiosk.id)}
                                                        name={`video-${kiosk.id}`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedKioskAdmins.has(kiosk.id) || selectedMovementNotifications.has(kiosk.id)}
                                                        onChange={handleMovementNotificationsCheckboxChange}
                                                        disabled={selectedKioskAdmins.has(kiosk.id) || !selectedKiosks.has(kiosk.id)}
                                                        name={`movement-${kiosk.id}`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            )
                        }
                        <DialogActions>
                        { kioskSelectionError && (
                                    <Alert severity="error">{kioskSelectionError}</Alert>
                                )}
                            <Button onClick={handleDialogClose} color="primary" variant='contained'>
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant='contained' disabled={loadingAddOrUpdate}>
                                {isEditing ? 
                                    ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Update') : 
                                    ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Add')
                                }
                            </Button>
                        </DialogActions>
                    
                    </form>
                    
                </DialogContent>
            </Dialog>

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={10000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
                        
            {showSuccess && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showSuccess}
                autoHideDuration={5000}
                message={successMessage}
                onClose={() => setShowSuccess(false)}
                >
                    <Alert severity="success" onClose={() => setShowSuccess(false)}>
                    {successMessage}
                    </Alert>
                </Snackbar>
            )}

            
            <Box my={4}>
                <div style={{ height: gridHeight, width: '100%' }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columns} 
                            loading={gridLoading}
                            pageSize={5} 
                            components={{
                                Header: {
                                    cell: (params) => <strong>{params.value}</strong>
                                }
                            }}
                        />
                         
                    </div>
                
            </Box>

        </Layout>
    );
}

export default UsersGrid;
