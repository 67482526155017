import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, Autocomplete, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Container, Grid, Box, Alert, Snackbar, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const NumberPlatesGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { globalTenantId } = useContext(AuthContext);
    const [sharePlates, setSharePlates] = useState(false);
    const [sharingMasterTenantID, setSharingMasterTenantID] = useState(null);
    const [sharingMasterTenantName, setSharingMasterTenantName] = useState('');
    const [responderAliases, setResponderAliases] = useState([]);
    const [showAliases, setShowAliases] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [allUsers, setAllUsers] = useState([]); // To hold all available users

    useEffect(() => {
        if (!globalTenantId) return;   
        fetchData();
        fetchResponderAliases(); 
        fetchUsers(); // Fetch users

    }, [globalTenantId]);
    
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 200;
        
        setGridHeight(calculatedHeight);
    }, []); 
    
    // State for form inputs
    const [form, setForm] = useState({
        plate: '', name: '', responderAliasId: null, mobileConfirmationNumber: '', userIdConfirmation: null, internalName: ''
    });
    
    const fetchResponderAliases = () => {

        axios.get(`/api/responder-aliases-isenabled`,  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            if (response.data.SettingAllowAliasesForNumberPlates === true) {
                setShowAliases(true);
            }
            else {
                setShowAliases(false);
            }
        })

        axios.get('/api/responder-aliases-kiosk',  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            setResponderAliases(response.data);
        
        })
        .catch(error => console.error('Error fetching responder aliases:', error));
    };

    const fetchUsers = () => {
        axios.get('/api/users', { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
          setAllUsers(result.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
      };

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // Function to handle form submission
    const handleAddSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
        axios.post('/api/number-plates', form, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
            setDialogOpen(false);
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form
            resetForm();
            // Refresh the list of number plates
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };

    const fetchData = () => {
        setGridLoading(true);
        // get the share plates setting
        axios.get('/api/number-plates/is-shared', { params: { tenantId: globalTenantId } })
        .then(result => {
            let shareId = null;
            setSharePlates(result.data.SharePlates);
            if (result.data.SharePlates) {
                shareId = result.data.SharingPlatesMasterTenantID; 
                setSharingMasterTenantID(shareId);
                setSharingMasterTenantName(result.data.masterTenantName);
            }
            else {
                setSharingMasterTenantID(null);
            }
            // get the plates either from the shared tenant or the global one if sharing is not enabled
            axios.get('/api/number-plates', { params: { tenantId: shareId || globalTenantId } })
            .then(result => {
                let data = result.data;
                setGridLoading(false);
                setRows(data);
            })
            .catch(error => {
                setGridLoading(false);
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });

    };

    // State for editing mode and edited entry
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);

    // Function to handle editing
    const handleEditClick = (entry) => {
        setForm({ plate: entry.plate, name: entry.name, responderAliasId: entry.responderAliasId,
            mobileConfirmationNumber: entry.mobileConfirmationNumber, userIdConfirmation: entry.userIdConfirmation, internalName: entry.internalName });
        setEditedEntry(entry);
        setIsEditing(true);
        setDialogOpen(true);
    };

    // Function to handle cancel action
    const handleCancelEdit = () => {
        setDialogOpen(false);
        resetForm();// Reset the form to its initial state
        setIsEditing(false); // Exit editing mode
        setEditedEntry(null); // Clear edited entry
    };
    
    // Function to handle update submission
    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
        axios.put(`/api/number-plates/${editedEntry.id}`, form, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result=> {
            setDialogOpen(false);
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of number plates
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };

    const handleSharePlateChange = (event) => {
        setSharePlates(event.target.checked);

        // update the share plates setting
        axios.post(`/api/number-plates/is-shared?tenantId=${globalTenantId}`, { SharePlates: event.target.checked })
        .then(result=> {
            // do nothing
            fetchData();
        })
        .catch(error => {
            console.error('Error updating share plates setting:', error);
        });
    };

    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/number-plates/${id}`, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of number plates
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting number plate:', error)
        });
    };

    function resetForm()
    {
        setForm({ plate: '', name: '', responderAliasId: null, mobileConfirmationNumber: '', userIdConfirmation: null, internalName: '' });
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        resetForm();
    };

    const handleDialogOpen = async () => {
        setDialogOpen(true);
    };

    const columns = [
        // 100% of available space

        { field: "plate", headerName: "Plate", flex: 1},
        { field: "name", headerName: "Welcome Name", flex: 1},
        { field: "internalName", headerName: "Internal Name", flex: 1},
        { field: "mobileConfirmationNumber", headerName: "Mobile Confirm", flex: 1},
        { field: "userIdConfirmation", headerName: "User Confirm", flex: 1, 
            valueGetter: (params) => {
                const user = allUsers.find(user => user.id === params.row.userIdConfirmation);
                return user ? `${user.first_name} ${user.last_name}` : '';
            }},
        ...(showAliases ? [{
            field: "responderAlias", 
            headerName: "Alias", 
            flex: 1,
            valueGetter: (params) => {
                const alias = responderAliases.find(alias => alias.id === params.row.responderAliasId);
                return alias ? alias.alias : '';
            }
        }] : []),
        {
            field: "edit",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button onClick={() => handleEditClick(params.row)} variant="contained" color="primary">
                    Edit
                </Button>
            )
        },
        { field: 'delete', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];

    return (
        <Layout title='Number Plates for ANPR'>   
            <Grid container>
                <Grid item xs={10}>
                    <Tooltip title={
                        <Typography>
                            <>
                                When this option is selected, the plates listed on this screen is the master list. All other devices then use these plates. 
                                {sharePlates && globalTenantId != sharingMasterTenantID ? 'This option is disabled because this is not the device sharing number plates.' : ''}
                            </>
                        </Typography>
                        }
                        >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sharePlates}
                                    onChange={handleSharePlateChange}
                                    name="sharePlates"
                                    color="primary"
                                    disabled={sharePlates && globalTenantId != sharingMasterTenantID}
                                />
                            }
                            label={'Share plates across all devices ' + 
                            (sharePlates && globalTenantId != sharingMasterTenantID ? `(plates are shared with ${sharingMasterTenantName})` : '')}
                        />
                        
                    </Tooltip>                
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}} >
                    <Button onClick={() => handleDialogOpen(null)} variant="contained" color="primary">
                        Add Number Plate
                    </Button>
                </Grid>
            </Grid>
            {/* Form to add number plate */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit}>
                    <DialogTitle id="form-dialog-title">{isEditing ? 'Edit Number Plate' : 'Add Number Plate'}</DialogTitle>
                    <DialogContent>

                            <Grid container spacing={1} alignItems="center">
                            
                                <Grid item xs={12}>
                                    <Tooltip title="The vehicle number plate">
                                    <TextField
                                        fullWidth
                                        label="Plate"
                                        name="plate"
                                        value={form.plate}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    /></Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title="A friendly name that will show on the kiosk on successful entry after 'Welcome...'">
                                    <TextField
                                        fullWidth
                                        label="Welcome name shown on kiosk"
                                        name="name"
                                        value={form.name}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title="Your own name for this number plate">
                                    <TextField
                                        fullWidth
                                        label="Internal name"
                                        name="internalName"
                                        value={form.internalName}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                <Tooltip title="You may choose to secure automatic ANPR gate opens with a second step verification.This cannot be combined with mobile-based ANPR authorisation. By selecting a user, they will receive a push notification to their Entrinsic Connect app. The gate will open only after positively confirming.">
                                <Autocomplete
                                    id="users-autocomplete"
                                    options={allUsers}
                                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`} // Updated this line
                                    value={allUsers.find(user => user.id === form.userIdConfirmation) || null} // Set the selected value
                                    onChange={(event, newValue) => {
                                        setForm(prevForm => ({
                                            ...prevForm,
                                            userIdConfirmation: newValue ? newValue.id : null 
                                        }));
                                    }}
                                    disabled={form.mobileConfirmationNumber != '' && form.mobileConfirmationNumber != null }
                                renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Optional 2-step verification user"
                                            margin="normal"
                                        />
                                    )}
                                />
                                </Tooltip>

                            </Grid>

                            <Grid item xs={12}>
                                <Tooltip title="You may choose to secure automatic ANPR gate opens with a second step verification. This cannot be combined with user-based ANPR authorisation. By specifying a mobile number, the user will be sent a text message to positively confirm the gate open.">
                                    <TextField
                                        fullWidth
                                        label="Optional 2-step verification mobile"
                                        name="mobileConfirmationNumber"
                                        value={form.mobileConfirmationNumber}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        disabled={form.userIdConfirmation != null}
                                    />
                                </Tooltip>
                            </Grid>
                                
                            { showAliases && (
                                <Grid item xs={12}>
                                    <Tooltip title="Specify which alias this number plate will notify on successful entry.">
                                    <Autocomplete
                                        options={responderAliases}
                                        getOptionLabel={(option) => option.alias} 
                                        value={responderAliases.find(alias => alias.id === form.responderAliasId) || null} // Set the selected value
                                        onChange={(event, newValue) => {
                                            setForm(prevForm => ({
                                                ...prevForm,
                                                responderAliasId: newValue ? newValue.id : null 
                                            }));
                                        }}
                                        
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Responder Alias"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    </Tooltip>
                                </Grid>)}                            
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelEdit} color="primary" variant='contained'>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant='contained'>
                            {isEditing ? 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Update') : 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Add')
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={10000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
            <Box my={4}>
                <div style={{ height: gridHeight, width: '100%' }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columns} 
                            pageSize={5} 
                            loading={gridLoading}
                            components={{
                                Header: {
                                    cell: (params) => <strong>{params.value}</strong>
                                }
                            }}
                        />
                         
                    </div>
                
            </Box>

        </Layout>
    );
}

export default NumberPlatesGrid;
