import * as React from 'react';
import { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CircularProgress, Tooltip } from '@mui/material';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.entrinsic.io/">
        Entrinsic Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();


const SignUpForm = ({ setShowDialog, setShowAlert, setAlertMessage, navigate }) => {
  const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [signingUp, setSigningUp] = useState(false);
    const [confirmEmailError, setConfirmEmailError] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const captchaToken = useRef(null);
    const formRef = React.useRef(null);
    const [termsChecked, setTermsChecked] = useState(false);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [organisationError, setOrganisationError] = useState("");


    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('signup');
        captchaToken.current = token;
    };
    
    
  
    const isStrongPassword = (password) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;

        return regex.test(password);
    };

    const isValidEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
  };

  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
    if (!formRef.current) return;

      await handleReCaptchaVerify();
  
      const data = new FormData(formRef.current);
  
      const firstName = data.get('firstName');
      const lastName = data.get('lastName');
      const organisation = data.get('organisation');

      if (!firstName.trim()) {
        setFirstNameError("Please enter your first name.");
        return;
      }
      else
      {
        setFirstNameError("");
      }

      if (!lastName.trim()) {
        setLastNameError("Please enter your last name.");
        return;
      }
      else
      {
        setLastNameError("");
      }

      if (!organisation.trim()) {
        setOrganisationError("Please enter a name for your device, home or business.");
        return;
      }
      else
      {
        setOrganisationError("");
      }

      const email = data.get('email');
  
      if (email !== confirmEmail) {
        setConfirmEmailError("Email addresses do not match!");
        return;
      } else {
        setConfirmEmailError("");
      }
  
      if (!isValidEmail(email)) {
        // Set an appropriate error state and message
        // For example, if you have a state for email errors:
        setEmailError("Please enter a valid email address.");
        return;
      } else {
          // Reset or clear the email error state/message
          setEmailError("");
      }
  
      if (!captchaToken.current) {
        // Handle the error - CAPTCHA not completed
        setAlertMessage("Please complete the CAPTCHA verification.");
        setShowAlert(true);
        return;
      }
  
  
      const password = data.get('password');
      const confirmPassword = data.get('confirmPassword');
  
      if (!isStrongPassword(password)) {
        setPasswordError("Password should be strong with at least 8 characters, including uppercase, lowercase, number, and a special character.");
        return;
      } else {
        setPasswordError("");
      }
  
      if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match!");
        return;
      } else {
        setConfirmPasswordError("");
      }
  
      if (!termsChecked) {
        setAlertMessage("You must agree to the Terms and Conditions to sign up.");
        setShowAlert(true);
        return;
    }
      try {
        setSigningUp(true);
        const response = await fetch('/api/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            email: data.get('email'),
            organisation: data.get('organisation'),
            password: password,
            captchaToken: captchaToken.current // Include the reCAPTCHA token here
  
          })
        });
        setSigningUp(false);
        const responseData = await response.json();
        if (responseData.success) {
          // Handle success, e.g., navigate to login or show a success message
          setShowDialog(true);
  
        } else {
          // Handle error
          setAlertMessage(responseData.message);
          setShowAlert(true);
        }
      } catch (error) {
          setAlertMessage("An error occurred. Please try again.");
          setShowAlert(true);
      
      }
    };

    return (
      <form ref={formRef} noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  error={Boolean(firstNameError)}
                  helperText={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  error={Boolean(lastNameError)}
                  helperText={lastNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={Boolean(emailError)}
                  helperText={emailError}

                />
              </Grid> 
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="confirmEmail"
                  label="Confirm Email Address"
                  name="confirmEmail"
                  autoComplete="email"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  error={Boolean(confirmEmailError)}
                  helperText={confirmEmailError}
                />
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="This is a friendly name of your device or home or business. Examples are 'Home', 'The Gables' or 'Widgets Ltd'. You can always change it later.">
                <TextField
                  required
                  fullWidth
                  id="organisation"
                  label="Device, Home or Business Name"
                  name="organisation"
                  autoComplete=""
                  error={Boolean(organisationError)}
                  helperText={organisationError}
              />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={Boolean(confirmPasswordError)}
                    helperText={confirmPasswordError}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox value="agree" color="primary" />}
                    label={
                        <>
                            I agree to the <Link href="https://entrinsic.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                Terms and Conditions
                            </Link>
                        </>
                    }
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                />
            </Grid>
            </Grid>
           
             

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={signingUp}
            >
              {signingUp ? <CircularProgress size={25} /> : 'Sign Up'}
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            
          </form>
    );
};

export default function SignUpPage() {
  
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc1uyopAAAAANOL6i9Mi0q_sBDncbXU9Uoe1oLv">
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <img src='/Entrinsic_Logo.png'/>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }} style={{marginBottom: 40}}>
            Please enter the details below to sign up for your free account. No credit card is required. Upon clicking Sign Up, you will receive an email to verify your account.
            You may optionally purchase a subscription for advanced features as described on the <a href="https://entrinsic.io/pricing">Entrinsic Connect website</a>. No charges or fees are taken without your express permission.</Typography>
          
        </Box>
        <SignUpForm 
                        setShowDialog={setShowDialog} 
                        setShowAlert={setShowAlert} 
                        setAlertMessage={setAlertMessage} 
                        navigate={navigate} 
                    />
                    
        <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Sign Up Successful</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please check your inbox for a verification link to complete the sign-up process.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setShowDialog(false);
                    // Navigate to login page
                    // Assuming you have the useNavigate hook initialized
                    navigate("/login"); 
                }} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
            <Alert onClose={() => setShowAlert(false)} severity="error" variant="filled">
                {alertMessage}
            </Alert>
        </Snackbar>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}