import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function ResetPassword() {
    const defaultTheme = createTheme();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const response = await fetch('/api/resetpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();

            if (data.success) {
                //alert('If you have a registered account, a reset password email has been sent to the specified email address.');
                //navigate to the login page
                navigate('/login?passwordReset=true');


            } else {
                alert('There was an error sending the password reset email. Please try again later.');
            }
        } catch (error) {
            console.error("Reset password error:", error);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
          
          <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
            <Typography variant="h5" gutterBottom>
                Reset Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Button type="submit" fullWidth variant="contained" color="primary">
                    Submit
                </Button>
                <p style={{textAlign: 'center'}}><a href='/login'>Back to Login</a></p>
            </Box>
            </Box>
            
        </Container>
        </ThemeProvider>
    );
}

export default ResetPassword;
