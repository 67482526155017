import { ManageResponders } from '../../components/Settings/ManageResponders';
import { AuthContext } from '../../AuthContext';
import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../components/Layout';

const Aliases = () => {

    const { globalTenantId } = useContext(AuthContext);

    return (
        <Layout title='Aliases'> 
            <ManageResponders tenantId={globalTenantId}/>
        </Layout>
    );
}

export default Aliases;
