import React, { createContext, useContext, useState } from 'react';
const UnreadCountContext = createContext();

export const useUnreadCount = () => useContext(UnreadCountContext);

export const UnreadCountProvider = ({ children }) => {
  const [unreadCounts, setUnreadCounts] = useState({});
  
  // Placeholder for a method to trigger the update from Navigation
  const refreshUnreadCounts = () => {};

  return (
    <UnreadCountContext.Provider value={{ unreadCounts, setUnreadCounts, refreshUnreadCounts }}>
      {children}
    </UnreadCountContext.Provider>
  );
};
