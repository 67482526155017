import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from '../components/Layout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

const Pay = () => {
    const defaultTheme = createTheme();
    const  Paddle  =  window.Paddle;

    useEffect(() => {
      (async () => {
        if (Paddle) {
          const paddleEnv = (await axios.get('/api/setting?name=PADDLE_ENVIRONMENT')).data.value;
          const paddleAuth = (await axios.get('/api/setting?name=PADDLE_TOKEN')).data.value;

          if (paddleEnv == 'sandbox') Paddle.Environment.set(paddleEnv);
          Paddle.Setup({
              token: paddleAuth,
          });

        }
      })();
    }, []);

    const checkoutComplete = (data) => {
        console.log('checkout complete', data);
    }

    return (
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

                <Typography variant="h6" gutterBottom component="div">
                    Payment Form Loading...
                </Typography>
                
            
        </Box>
        </Container>
        </ThemeProvider>
    );
};

export default Pay;
