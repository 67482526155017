import React from 'react';
import './axiosConfig';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './protectedRoute';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme'; // Import your custom theme
import { routes } from './routesConfig';
import { UnreadCountProvider } from './UnreadCountContext';

const App = () => {
  return (
    <AuthProvider>
      <UnreadCountProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {routes.map((route, index) => (
              route.isPublic ? 
              <Route key={index} path={route.path} element={<route.component />} /> :
              <Route key={index} path={route.path} element={
                <ProtectedRoute requiredRole={route.requiredRole}><route.component /></ProtectedRoute>
              } />
            ))}
          </Routes>
        </Router>
      </ThemeProvider>
      </UnreadCountProvider>
    </AuthProvider>
  );
};


export default App;
