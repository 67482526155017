import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, FormControlLabel, Container, Grid, Box, Dialog, Badge } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../AuthContext';
import { useUnreadCount } from '../../UnreadCountContext';


const VideoMessageGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'id',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false); // New state for loading status
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [open, setOpen] = useState(false); // Add this state to control dialog
    const [videoURL, setVideoURL] = useState(null); // Add this state to store the video blob URL
    const [deletingIds, setDeletingIds] = useState(new Set());
    const { globalTenantId } = useContext(AuthContext);
    const { refreshUnreadCounts } = useUnreadCount();



    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return;
        fetchData();
        // useeffect initially fires twice because of strict mode in index.js. Should not remove that - see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
        
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, globalTenantId]);
    
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 150;
        
        setGridHeight(calculatedHeight);
    }, []); 
   
    const fetchData = () => {
        setLoading(true);

        const requestBody = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort
        };

        axios.post('/api/videoMessages', requestBody, { params: { tenantId: globalTenantId } })
        .then(result => {
           const data = result.data;
            setRows(data.rows || []);
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false); // Set loading to false when fetch is done (either success or error)
        });
    };

    const handlePlayVideo = (id, isUnread) => {
        axios.get(`/api/videoMessages/${id}`, { params: { tenantId: globalTenantId } })
        .then(result => {
            const videoUrl = result.data.cloudinaryUrl_Video;
            setVideoURL(videoUrl);
            setOpen(true);
            
            if (isUnread) markMessageAsRead(id);

        })
        .catch(error => console.error('Error fetching video:', error));
    };
    
    const markMessageAsRead = (id) => {
        axios.post(`/api/videoMessage-read`, { id: id, tenantId: globalTenantId })
        .then(() => {
            // update the UI to reflect the change in read status
            setRows(rows.map(row => {
                if (row.id === id) {
                    return { ...row, isUnread: false }; // Update the isUnread flag
                }
                return row;
            }));
            refreshUnreadCounts.current(); // Call the method to refresh the unread message count

        })
        .catch(error => console.error('Error marking message as read:', error));
    };
    
    const handleClose = () => {
        setOpen(false);
        setVideoURL(null); // Clear the video URL to release object URL
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to delete this item?")) return;
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/videomessages/${id}`, { params: { tenantId: globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            refreshUnreadCounts.current(); // Call the method to refresh the unread message count

            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting pin:', error)
        });
    }
    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "createdAt", headerName: "Date/Time", width: 250,
            valueGetter: (params) => {
                const date = new Date(params.value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            }
        },
        {
            field: "cloudinaryUrl_Preview",
            headerName: "Preview",
            width: 150,
            renderCell: (params) => {
                return (
                params.value ? (
                    <img src={params.value} alt="video preview" width="50" />
                    
                ) : (
                    <div>No preview</div>
                )
                );
            }
        },        
        {
            field: "ResponderAlias.Alias",
            headerName: "Alias",
            width: 150,
            valueGetter: (params) => {
                return params.row.ResponderAlias ? params.row.ResponderAlias.Alias : '';
            }
        },
        { 
            field: "play", 
            headerName: "", 
            width: 100,
            renderCell: (params) => {
                {/* Add the Badge component */}
                
                const isUnread = params.row.isUnread; 
                return (
                    <Box position="relative">
                        
                            <Badge 
                                color="primary" 
                                badgeContent="" 
                                invisible={!isUnread}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '.MuiBadge-badge': {
                                        height: '12px', // Adjust the height
                                        minWidth: '12px', // Adjust the minimum width
                                        fontSize: '0.4rem', // Adjust the font size
                                        borderRadius: '50%', // Makes the badge circular
                                        backgroundColor: 'red', // Text color
                                        top: 2,
                                    }
                                }}
                            >
                                
                <Button variant="contained" color="primary" onClick={() => handlePlayVideo(params.row.id, params.row.isUnread)}>
                    Play
                </Button>
                </Badge></Box>
                );
        }},
        { field: 'delete', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];
      
    return (
       
            <Layout title='Video Messages'>
                    <Box my={4}>
                        
                        {/* Show DataGrid if not loading */}
                        {
                            <div style={{ height: gridHeight, width: '100%' }}>
                                <DataGrid
                                    loading={loading} 
                                    rows={rows} 
                                    columns={columns} 
                                    pageSizeOptions={[10]}
                                    paginationMode='server'
                                    paginationModel={paginationModel}
                                    sortModel={sortModel}
                                    onPaginationModelChange={setPaginationModel}
                                    onSortModelChange={setSortModel}
                                    rowCount={rowCount}
                                    sortingOrder={['desc', 'asc']}
                                    
                                />
                            </div>
                        }
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <video controls src={videoURL} style={{ width: '100%' }} />
                    </Dialog>
                
            </Layout>
        );
}

export default VideoMessageGrid;
