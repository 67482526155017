import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Box, Dialog } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const LogGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'date',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    
    const [filters, setFilters] = useState({
        search: '',
        excludeKeepalive: false
    });
    const [loading, setLoading] = useState(false); // New state for loading status
    const [openImage, setOpenImage] = useState(false);
    const [largeImageSrc, setLargeImageSrc] = useState(null);
    
    const filterRef = useRef(null);
    const [gridHeight, setGridHeight] = useState(400);  // default value

     // date range filter
     const [startDate, setStartDate] = useState(null);
     const [endDate, setEndDate] = useState(null);

     const { globalTenantId } = useContext(AuthContext);
 
    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return; 
        fetchLogs();
        // useeffect initially fires twice because of strict mode in index.js. Should not remove that - see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
        
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, filters, globalTenantId]);
    
    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed
    
    const fetchLogs = () => {
        setLoading(true);

        const requestBody = {
            ...filters,
            startDate: startDate ? startDate.toISOString() : null, // Include start date in request
            endDate: endDate ? endDate.toISOString() : null, // Include end date in request
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort
        };

        axios.post('/api/logs', requestBody, { params: { tenantId: globalTenantId } })
        .then(result => {
           const data = result.data;
           console.log(data)
            setRows(data.rows || []);
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false); // Set loading to false when fetch is done (either success or error)
        });
    };

    const handleImageClick = (src) => {
        setLargeImageSrc(src);
        setOpenImage(true);
    };
    
    const handleCloseImage = () => {
        setOpenImage(false);
    };
    

    const columns = [
        {
          field: "date",
          headerName: "Date/Time",
          flex: 0.2,
          valueGetter: (params) => {
            const date = new Date(params.value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
          },
        },
        {
          field: "event",
          headerName: "Event",
          flex: 0.45,
          renderCell: (params) => (
            params.row.IsImageData === true ?
              <img src={params.value} alt="log" width="50" style={{ cursor: 'pointer' }} onClick={() => handleImageClick(params.value)} /> :
              <span>{params.value}</span>
          ),
        },
        {
          field: "user",
          headerName: "User",
          flex: 0.2,
          valueGetter: (params) => params.row.User?.first_name + ' ' + params.row.User?.last_name
        }
      ];
      

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchLogs(0);
    };

    return (
       
            <Layout title='Logs'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleFilterSubmit} ref={filterRef}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6} lg={2}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => { setStartDate(newValue); }}
                                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" margin="normal" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={2}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => { setEndDate(newValue); }}
                                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" margin="normal" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    fullWidth
                                    label="Search"
                                    name="search"
                                    value={filters.search}
                                    onChange={handleFilterChange}
                                    variant="outlined"
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="excludeKeepalive"
                                            checked={filters.excludeKeepalive}
                                            onChange={handleFilterChange}
                                        />
                                    }
                                    label="Exclude 'keepalive' events"
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Box my={4}>
                        
                        {/* Show DataGrid if not loading */}
                        {
                            <div style={{ height: gridHeight, width: '100%' }}>
                                <DataGrid
                                    loading={loading} 
                                    rows={rows} 
                                    columns={columns} 
                                    pageSizeOptions={[10]}
                                    paginationMode='server'
                                    paginationModel={paginationModel}
                                    sortModel={sortModel}
                                    onPaginationModelChange={setPaginationModel}
                                    onSortModelChange={setSortModel}
                                    rowCount={rowCount}
                                    sortingOrder={['desc', 'asc']}
                                    
                                />
                            </div>
                        }
                    </Box>

                    <Dialog open={openImage} onClose={handleCloseImage} maxWidth="md" fullWidth>
                        <img src={largeImageSrc} alt="Large Log" style={{ width: '100%' }} />
                    </Dialog>
                </LocalizationProvider>
            </Layout>
        );
                    }

export default LogGrid;
