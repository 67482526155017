import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, Autocomplete, FormControlLabel, Container, Grid, Box, Alert, Snackbar, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const PinsGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { globalTenantId } = useContext(AuthContext);
    const [sharePINs, setSharePINs] = useState(false);
    const [sharingMasterTenantID, setSharingMasterTenantID] = useState(null);
    const [sharingMasterTenantName, setSharingMasterTenantName] = useState('');
    const [responderAliases, setResponderAliases] = useState([]);
    const [showAliases, setShowAliases] = useState(false);

    useEffect(() => {
        if (!globalTenantId) return; 
        fetchData();
        fetchResponderAliases(); 
    }, [globalTenantId]);
    const addRef = useRef(null);
    
    useEffect(() => {
        const formHeight = addRef.current.getBoundingClientRect().height;
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - formHeight - 150;
        
        setGridHeight(calculatedHeight);
    }, []); 
    
    // State for form inputs
    const [form, setForm] = useState({
        pin: '', name: '', responderAliasId: null
    });
    
    const fetchResponderAliases = () => {

        axios.get(`/api/responder-aliases-isenabled`,  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            if (response.data.SettingAllowAliasesForPINs === true) {
                console.log('true')
                setShowAliases(true);
            }
            else {
                setShowAliases(false);
            }
        })

        axios.get('/api/responder-aliases-kiosk',  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            setResponderAliases(response.data);
        
        })
        .catch(error => console.error('Error fetching responder aliases:', error));
    };



    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // Function to handle form submission
    const handleAddSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
        axios.post('/api/pins', form, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(data => {
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form
            resetForm();
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };

    const fetchData = () => {
        setGridLoading(true);
        // get the share PINs setting
        axios.get('/api/pins/is-shared', { params: { tenantId: globalTenantId } })
        .then(result => {
            let shareId = null;
            setSharePINs(result.data.SharePINs);
            console.log(result.data)
            if (result.data.SharePINs) {
                shareId = result.data.SharingPINsMasterTenantID; 
                setSharingMasterTenantID(shareId);
                setSharingMasterTenantName(result.data.masterTenantName);
            }
            else {
                setSharingMasterTenantID(null);
            }
            // get the PINs either from the shared tenant or the global one if sharing is not enabled
            axios.get('/api/pins', { params: { tenantId: shareId || globalTenantId } })
            .then(result => {
                let data = result.data;
                setGridLoading(false);
                setRows(data);
            })
            .catch(error => {
                setGridLoading(false);
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });

    };

    // State for editing mode and edited entry
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);

    // Function to handle editing
    const handleEditClick = (entry) => {
        setForm({ pin: entry.pin, name: entry.name, responderAliasId: entry.responderAliasId });
        setEditedEntry(entry);
        setIsEditing(true);
    };

    // Function to handle cancel action
    const handleCancelEdit = () => {
        resetForm();
        setIsEditing(false); // Exit editing mode
        setEditedEntry(null); // Clear edited entry
    };
    
    // Function to handle update submission
    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
        axios.put(`/api/pins/${editedEntry.id}`, form, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(data => {
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };

    const handleSharePINChange = (event) => {
        setSharePINs(event.target.checked);

        // update the share pins setting
        axios.post(`/api/pins/is-shared?tenantId=${globalTenantId}`, { SharePINs: event.target.checked })
        .then(result=> {
            // do nothing
            fetchData();
        })
        .catch(error => {
            console.error('Error updating share PINs setting:', error);
        });
    };
    
    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/pins/${id}`, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting pin:', error)
        });
    };

    function resetForm()
    {
        setForm({ pin: '', name: '', responderAliasId: null });
        setIsEditing(false);
        setEditedEntry(null);
    }

    const columns = [
        // 100% of available space

        { field: "pin", headerName: "PIN", flex: 1},
        { field: "name", headerName: "Name", flex: 1},
        // Include the responderAlias column conditionally
        ...(showAliases ? [{
            field: "responderAlias", 
            headerName: "Alias", 
            flex: 1,
            valueGetter: (params) => {
                const alias = responderAliases.find(alias => alias.id === params.row.responderAliasId);
                return alias ? alias.alias : '';
            }
        }] : []),        
        {
            field: "edit",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button onClick={() => handleEditClick(params.row)} variant="contained" color="primary">
                    Edit
                </Button>
            )
        },
        { field: 'delete', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];

    return (
        <Layout title='Access PINs'>   

            {/* Form to add pin */}
            <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit} ref={addRef}>
                <Grid item xs={12}>
                    <Tooltip title={
                        <Typography>
                            <>
                                When this option is selected, the PINs listed on this screen is the master list. All other devices then use these PINs. 
                                {sharePINs && globalTenantId != sharingMasterTenantID ? 'This option is disabled because this is not the device sharing PINs.' : ''}
                            </>
                        </Typography>
                        }
                        >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sharePINs}
                                    onChange={handleSharePINChange}
                                    name="sharePINs"
                                    color="primary"
                                    disabled={sharePINs && globalTenantId != sharingMasterTenantID}
                                />
                            }
                            label={'Share PINs across all devices ' + 
                            (sharePINs && globalTenantId != sharingMasterTenantID ? `(PINs are shared with ${sharingMasterTenantName})` : '')}
                        />
                    </Tooltip>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="PIN"
                            name="pin"
                            value={form.pin}
                            onChange={handleInputChange}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    { showAliases && (
                    <Grid item xs={3}>
                        <Tooltip title="Specify which alias this PIN will notify on successful entry.">
                        <Autocomplete
                            options={responderAliases}
                            getOptionLabel={(option) => option.alias} 
                            value={responderAliases.find(alias => alias.id === form.responderAliasId) || null} // Set the selected value
                            onChange={(event, newValue) => {
                                setForm(prevForm => ({
                                    ...prevForm,
                                    responderAliasId: newValue ? newValue.id : null 
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Responder Alias"
                                    variant="outlined"
                                />
                            )}
                        />
                        </Tooltip>
                    </Grid>)}
                    <Grid item xs={1}>
                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loadingAddOrUpdate}>
                            {loadingAddOrUpdate ? <CircularProgress size={24} /> : (isEditing ? 'Update' : 'Add')}
                        </Button>
                    </Grid>
                    {/* Conditional rendering of Cancel button */}
                    {isEditing && (
                        <Grid item xs={1}>
                            <Button onClick={handleCancelEdit} variant="contained" fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </form>

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={5000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
            <Box my={4}>
                <div style={{ height: gridHeight, width: '100%' }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columns} 
                            loading={gridLoading}
                            pageSize={5} 
                            components={{
                                Header: {
                                    cell: (params) => <strong>{params.value}</strong>
                                }
                            }}
                        />
                         
                    </div>
                
            </Box>

        </Layout>
    );
}

export default PinsGrid;
