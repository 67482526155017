import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogsIcon from '@mui/icons-material/Topic';
import PreviewIcon from '@mui/icons-material/Preview';
import PinIcon from '@mui/icons-material/Pin';
import ContactsIcon from '@mui/icons-material/Contacts';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { Badge } from '@mui/material';

export function MainListItems(props) {
  const { userRole, unreadCount } = props;


  return (
  <React.Fragment>
    {userRole === 'SUPER_ADMIN' && (
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon/>
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItemButton>
      )}
      {userRole.startsWith('CLIENT_') && (
        <>
    <ListItemButton onClick={props.onGoToDashboard} id='dashboard_button'>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToLogs}>
      <ListItemIcon>
        <LogsIcon />
      </ListItemIcon>
      <ListItemText primary="Logs" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToNumberPlates}>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Number Plates" />
    </ListItemButton>    
    <ListItemButton onClick={props.onGoToPins}>
    <ListItemIcon>
        <PinIcon />
      </ListItemIcon>
      <ListItemText primary="PINs" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToMessages}>
      <ListItemIcon>
      <Badge
      color="primary"
      badgeContent={unreadCount}
      invisible={unreadCount === 0}
      sx={{
        '.MuiBadge-badge': {
          height: '12px',
          minWidth: '12px',
          fontSize: '0.4rem',
          borderRadius: '50%',
          backgroundColor: 'red',
        }
      }}
    >
        <VideoChatIcon /></Badge>
      </ListItemIcon>
      <ListItemText primary="Video Messages" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToSnapshots}>
      <ListItemIcon>
        <PhotoLibraryIcon />
      </ListItemIcon>
      <ListItemText primary="Snapshots" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToMovementVideos}>
      <ListItemIcon>
        <OndemandVideoIcon />
      </ListItemIcon>
      <ListItemText primary="Movement Videos" />
    </ListItemButton>
    </>
      ) }
 
  </React.Fragment>
);
  }

export function SecondaryListItems(props) {
  const { userRole } = props;
  return (
  <React.Fragment>
    {userRole.startsWith('CLIENT_') && (
        <>
    <ListSubheader component="div" inset>
      More actions
    </ListSubheader>
    <ListItemButton onClick={props.onPreviewKiosk}>
      <ListItemIcon>
        <PreviewIcon />
      </ListItemIcon>
      <ListItemText primary="Preview Kiosk" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToSettings}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Device Settings" />
    </ListItemButton>
    </>
    )}
    </React.Fragment>
  )}

  export function GlobalListItems(props) {
    const { userRole } = props;

    return (
      <React.Fragment>
        <ListSubheader component="div" inset>
      Global actions
    </ListSubheader>

    { userRole === 'CLIENT_ADMIN' && (
      <>
    <ListItemButton onClick={props.onGoToUsers}>
    <ListItemIcon>
      <PeopleIcon />
    </ListItemIcon>
    <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton onClick={props.onGoToResponderAliases}>
    <ListItemIcon>
      <ContactsIcon />
    </ListItemIcon>
    <ListItemText primary="Aliases" />
    </ListItemButton>
    {
      !props.user.partnerId && (
      <ListItemButton onClick={props.onUpgrade}>
        <ListItemIcon>
          <UpgradeIcon />
        </ListItemIcon>
        <ListItemText primary="Upgrade" />
        </ListItemButton>
  
      )
    }
    </>
  )}
    
    
    <ListItemButton onClick={props.onSignOut}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItemButton>
  </React.Fragment>
);
  }