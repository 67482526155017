import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, FormControlLabel, Container, Grid, Box, Dialog, Typography } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const MovementVideosGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'id',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false); // New state for loading status
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [deletingIds, setDeletingIds] = useState(new Set());
    const { globalTenantId } = useContext(AuthContext);

    const [filters, setFilters] = useState({
        search: '',
    });
    const [openImage, setOpenImage] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [movementVideosEnabled, setMovementVideosEnabled] = useState(true);
    const filterRef = useRef(null);

    // date range filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return;
        fetchData();
        // useeffect initially fires twice because of strict mode in index.js. Should not remove that - see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
        
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, filters, globalTenantId]);
    
    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed
    const fetchData = () => {
        setLoading(true);

        const requestBody = {
            ...filters,
            startDate: startDate ? startDate.toISOString() : null, // Include start date in request
            endDate: endDate ? endDate.toISOString() : null, // Include end date in request
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort,
        };

        axios.get(`/api/movement-videos?tenantId=${globalTenantId}`, { params: requestBody })
        .then(result => {
           const data = result.data;
           console.log(data);
            setRows(data.rows || []);
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false); // Set loading to false when fetch is done (either success or error)
        });
        axios.get(`/api/tenants/${globalTenantId}`, { params: { fields: ['SettingEnableMovementVideo'] } }).then(result => {
            setMovementVideosEnabled(result.data.SettingEnableMovementVideo);
        }
        ).catch(error => console.error('Error fetching data:', error));
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to delete this item?")) return;
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/movement-videos/${id}`, { params: { tenantId: globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting movement video:', error)
        });
    }
    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "createdAt", headerName: "Date/Time", width: 250,
            valueGetter: (params) => {
                const date = new Date(params.value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            }
        },
        {
            field: "cloudinaryUrl_Preview",
            headerName: "Preview",
            width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <img src={params.value} alt="snapshot preview" width="50" onClick={() => handleMediaClick(params.row.cloudinaryUrl_Video)}/>;
                } else {
                    return <div>No preview</div>;
                }
            }
        },        
        { 
            field: "play", 
            headerName: "", 
            width: 100,
            renderCell: (params) => {
                {/* Add the Badge component */}
                
                const isUnread = params.row.isUnread; 
                return (
                            
                <Button variant="contained" color="primary" onClick={() => handleMediaClick(params.row.cloudinaryUrl_Video)}>
                    Play
                </Button>
                );
        }},        { field: 'delete', headerName: '', width: 100, 
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];


    const handleMediaClick = (src) => {
        setVideoUrl(src);  // Consider renaming this state to `largeMediaSrc`
        setOpenImage(true);  // Consider renaming this state to `openMedia`
    };
    
    const handleCloseImage = () => {
        setOpenImage(false);
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchData();
    };
    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    
    return (
        <Layout title='Movement Videos'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {
                    !movementVideosEnabled &&
                        <Box mb={1} border={1} textAlign='center' padding={3}>
                            <Typography variant='body2'>Movement videos are disabled. To enable them, go to Device Settings &gt; Kiosk Settings</Typography>
                        </Box>
                }
            <form onSubmit={handleFilterSubmit} ref={filterRef}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => { setStartDate(newValue); }}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" margin="normal" />}
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => { setEndDate(newValue); }}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" margin="normal" />}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Box my={4}>
                    
                    {/* Show DataGrid if not loading */}
                    {
                        <div style={{ height: gridHeight, width: '100%' }}>
                            <DataGrid
                                loading={loading} 
                                rows={rows} 
                                columns={columns} 
                                pageSizeOptions={[10]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                onPaginationModelChange={setPaginationModel}
                                onSortModelChange={setSortModel}
                                rowCount={rowCount}
                                sortingOrder={['desc', 'asc']}
                                
                            />
                        </div>
                    }
                </Box>
                <Dialog open={openImage} onClose={handleCloseImage} fullWidth>
                        <video src={videoUrl} controls style={{ width: '100%', objectFit: 'contain' }} />
                </Dialog>
            </LocalizationProvider>
        </Layout>
    );
}

export default MovementVideosGrid;
