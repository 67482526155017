import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import Layout from '../../components/Layout';
import { AuthContext } from '../../AuthContext';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const MyAccount = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0); // zero-based page index
    const [rowCount, setRowCount] = useState(0); // total number of rows
    const [afterCursor, setAfterCursor] = useState(null);
    const { globalTenantId } = useContext(AuthContext);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const navigate = useNavigate();
    const [paymentDetails, setPaymentDetails] = useState(null);

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 250;
        
        setGridHeight(calculatedHeight);
    }, []); 
    

    const fetchTransactions = async (newCursor, newPage) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/customer-transactions?perPage=1000&afterCursor=${newCursor || ''}`);

            const newTransactions = response.data.data.map(transaction => ({
                ...transaction,
                product: `${transaction.items[0].price.name}\n${formatDate(transaction.billing_period.starts_at)} - ${formatDate(transaction.billing_period.ends_at)}`,
                payment_method: 
                                
                                `${transaction.payments[0].method_details.type == 'card' ? 
                                `${transaction.payments[0].method_details.card.type}
                                ending in ${transaction.payments[0].method_details.card.last4}` : transaction.payments[0].method_details.type}`,
                amount: `${(transaction.details.totals.grand_total / 100).toFixed(2)}`, // Assuming amount is in pence
                loading: false,
                transactionType: transaction.origin == 'subscription_payment_method_change' ? 'Updated payment' : 
                                transaction.origin == 'web' ? 'Subscription started' :
                                transaction.origin == 'subscription_update' ? 'Upgrade' : 
                                transaction.origin == 'subscription_recurring' ? 'Renewal' : transaction.origin
            }));

            setTransactions(newTransactions);
            

            const lastTransaction = response.data.data[response.data.data.length - 1];
            setAfterCursor(lastTransaction ? lastTransaction.id : null);

            // Update the row count with the estimated total from the API response
            setRowCount(response.data.meta.pagination.estimated_total);

            // fetch payment details
            axios.get(`/api/customers/get-update-payment-method-transaction`)
            .then(result => {
                setPaymentDetails(result.data);
                
            })

        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Format date to UK standard
    };
    useEffect(() => {
        fetchTransactions(null, page);
    }, [globalTenantId]);

    const handleDownloadInvoice = async (transactionId) => {
        setTransactions(currentTransactions =>
            currentTransactions.map(t => 
                t.id === transactionId ? { ...t, loading: true } : t
            )
        );
    
        try {
            const response = await axios.get(`/api/customer-invoice/${transactionId}`);
            if (response.status === 200) {
                window.location.href = response.data;
            }
        } catch (error) {
            console.error('Error downloading invoice:', error);
        } finally {
            setTransactions(currentTransactions =>
                currentTransactions.map(t => 
                    t.id === transactionId ? { ...t, loading: false } : t
                )
            );
        }
    };
    
    const renderDownloadButton = (params) => {
        return params.row.loading ? (
            <CircularProgress size={24} />
        ) : (
            params.row.origin!='subscription_payment_method_change' &&
            <Button
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={() => handleDownloadInvoice(params.row.id)}
            >
                Invoice
            </Button>
        );
    };
    
    const handleChangePaymentMethod = () => {
        // get the transaction
        window.open(`/pay?_ptxn=${paymentDetails.data.id}`);
    }

    const columns = [
        { field: 'transactionType', headerName: 'Transaction', width: 150 },
        { 
            field: 'billed_at', 
            headerName: 'Date', 
            width: 100,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString()
        },
        { field: 'currency_code', headerName: 'Currency', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'product', headerName: 'Product', flex: 1, renderCell: (params) => <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>  },
        { field: 'payment_method', headerName: 'Payment method', width: 150, renderCell: (params) => (
            <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>)
        },
        { 
            field: 'download', 
            headerName: '', 
            width: 120,
            renderCell: renderDownloadButton,
            disableClickEventBubbling: true, // This prevents row click events when clicking the button
        },

        // Add other columns as needed
    ];

    return (
        <Layout title='My Account'>
            <Grid container style={{marginBottom: 20}}>
                <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom component="div">
                        Your account transactions
                    </Typography>
                </Grid>
                {paymentDetails && (
                <Grid item xs={4} style={{textAlign: 'right'}} >
                    <Button variant="contained" color="primary" onClick={() => handleChangePaymentMethod()}>
                        Change payment method
                    </Button>
                </Grid>)}
            </Grid>
            <Grid item xs={12}>
                <div style={{ height: gridHeight, width: '100%' }}>
                    <DataGrid
                        rows={transactions}
                        columns={columns}
                        loading={loading}
                    />
                </div>
            </Grid>
        </Layout>
    );
};

export default MyAccount;
